import dayjs from 'dayjs';
import { TbMailForward } from 'react-icons/tb';
import { remark } from 'remark';
import { toast } from 'sonner';

import { cacheUtils } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import { Button } from '@eluve/components';
import { useAppointmentId } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';
import { SummarySectionMarkdownAction } from '@eluve/smart-blocks';

const appointmentPatientIdFragment = graphql(`
  fragment AppointmentPatientId on Appointments {
    __typename
    patientId
  }
`);

const externalPatientInfoFragment = graphql(`
  fragment ExternalPatientInfo on Patients {
    __typename
    id
    external_patients_info {
      __typename
      id
      email
    }
  }
`);

export const ClientRecapMailToAction: SummarySectionMarkdownAction = ({
  getSummary,
}) => {
  const logger = useNamedLogger('ClientRecapMailToAction');
  const appointmentId = useAppointmentId();

  const handleMailTo = async () => {
    const summary = String(
      await remark()
        .data('settings', {
          setext: true,
        })
        .process(getSummary() ?? ''),
    );

    if (!summary) {
      toast.info('Client recap summary is empty');
      return;
    }

    const { patientId } = cacheUtils.readFragment({
      fragment: appointmentPatientIdFragment,
      key: {
        id: appointmentId,
      },
    });

    const patient = cacheUtils.readFragment({
      fragment: externalPatientInfoFragment,
      key: {
        id: patientId ?? '',
      },
      strict: false,
    })?.external_patients_info[0];

    logger.info('Sending client recap to patient', {
      recapLength: summary.length,
      patientId: patient?.id,
      patientEmailLength: patient?.email?.length,
    });

    const subject = `Follow up from today's session (${dayjs().format('MMM DD')})`;

    window.open(
      `mailto:${patient?.email ?? ''}?subject=${subject}&body=${encodeURIComponent(summary)}`,
      '_blank',
    );
  };

  return (
    <TooltipLabel label="Send mail to patient">
      <Button
        size="icon"
        variant="ghost"
        className="hover:text-brand-9 hover:bg-transparent"
        onClick={handleMailTo}
      >
        <TbMailForward className="size-5" />
      </Button>
    </TooltipLabel>
  );
};
