import { CaretDownIcon } from '@radix-ui/react-icons';
import { Save } from 'lucide-react';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@eluve/components';
import {
  useAppointmentActivity,
  useAppointmentId,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { AppointmentPdf } from '@eluve/frontend-feature-appointment';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

export const AppointmentPDFOutputControls = () => {
  const summary = useSummary();
  const tenantId = useTenantIdFromParams();
  const { isSummarizationInProgress } = useAppointmentActivity();

  const appointmentId = useAppointmentId();

  const api = useApiClient();

  if (isSummarizationInProgress || !summary.isSummaryAvailable) {
    return null;
  }

  const handleSavePdf = async () => {
    if (!tenantId) {
      return;
    }
    const result = await api.appointments.getOrCreatePdfUrl({
      params: {
        tenantId,
        appointmentId,
      },
    });
  };

  return (
    <FeatureFlaggedComponent flag="PDF_OUTPUT">
      {summary.summary.type === 'SOAP' && (
        <Dialog>
          <Box hStack className="w-full gap-0">
            <DialogTrigger asChild>
              <Button variant="outline" className="w-full rounded-r-none">
                Preview PDF
              </Button>
            </DialogTrigger>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="rounded-l-none"
                >
                  <CaretDownIcon />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent side="top">
                <DropdownMenuItem
                  disabled={!tenantId}
                  className="flex cursor-pointer items-center gap-2"
                  onClick={handleSavePdf}
                >
                  <Save className="size-5" />
                  <span>Save PDF</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </Box>
          <DialogContent className="max-w-7xl">
            <DialogHeader>
              <DialogTitle>PDF Note</DialogTitle>
            </DialogHeader>
            <AppointmentPdf
              tenantId={tenantId ?? ''}
              appointmentId={appointmentId}
              data={summary.summary.data}
              showSoapHeaders={true}
            />
          </DialogContent>
        </Dialog>
      )}
    </FeatureFlaggedComponent>
  );
};
