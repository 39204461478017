import { ChevronDown, LanguagesIcon } from 'lucide-react';
import React from 'react';

import {
  Box,
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  ComboboxSelectCheck,
} from '@eluve/components';
import {
  SupportedLanguages,
  SupportedLanguagesByCode,
} from '@eluve/language-utils';

type LanguageSelectorComboBoxProps = {
  selectedLanguageCode: string;
  onSelectedLanguageCode: (languageCode: string) => Promise<void>;
  disabled?: boolean;
  preferredLanguages?: string[];
  selectButtonStyles?: string;
  showLanguageIcon?: boolean;
};

export const LanguageSelectorComboBox: React.FC<
  LanguageSelectorComboBoxProps
> = ({
  selectedLanguageCode,
  onSelectedLanguageCode,
  disabled = false,
  preferredLanguages = [],
  selectButtonStyles = '',
  showLanguageIcon = true,
}) => {
  const sortedLanguages = Object.entries(SupportedLanguages).sort(
    ([, codeA], [, codeB]) => {
      const isCodeAPreferred = preferredLanguages.includes(codeA) ? 0 : 1;
      const isCodeBPreferred = preferredLanguages.includes(codeB) ? 0 : 1;
      return isCodeAPreferred - isCodeBPreferred;
    },
  );

  return (
    <Combobox disabled={disabled}>
      <ComboboxSelectButton
        className={selectButtonStyles}
        dropdownIcon={<ChevronDown className="ml-2 h-4 w-4 shrink-0" />}
      >
        <Box hStack className="w-full overflow-hidden">
          {showLanguageIcon && <LanguagesIcon size={16} />}
          {SupportedLanguagesByCode[selectedLanguageCode]}
        </Box>
      </ComboboxSelectButton>
      <ComboboxDropdown
        searchPlaceholder="Select Language"
        className="min-w-52"
      >
        {sortedLanguages.map(([languageName, availableLanguageCode]) => (
          <ComboboxOption
            key={availableLanguageCode}
            onSelect={async () => {
              await onSelectedLanguageCode(availableLanguageCode);
            }}
          >
            <ComboboxSelectCheck
              className="mr-2"
              selected={availableLanguageCode === selectedLanguageCode}
            />
            {languageName}
          </ComboboxOption>
        ))}
      </ComboboxDropdown>
    </Combobox>
  );
};
