import { Loader2, PauseCircleIcon, PlayCircleIcon } from 'lucide-react';
import React from 'react';
import { match } from 'ts-pattern';

import { Box, Button } from '@eluve/components';
import { useCaptureEvent } from '@eluve/posthog-react';

import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../machines/transcription/TranscriptionMachineProvider';

interface AppointmentTranscriptionInSessionControlsProps {
  onEndSession?: () => void;
}

export const AppointmentTranscriptionInSessionControls: React.FC<
  AppointmentTranscriptionInSessionControlsProps
> = ({ onEndSession }) => {
  const appointmentId = useTranscriptionMachineSelector(
    (state) => state.context.appointmentId ?? '',
  );

  const captureEvent = useCaptureEvent({
    appointmentId,
  });

  const state = useTranscriptionMachineSelector((state) => {
    if (
      state.matches({
        InSession: {
          Recording: {
            MediaRecorder: 'Loading',
          },
        },
      })
    ) {
      return 'Loading';
    }

    if (
      state.matches({
        InSession: 'Recording',
      })
    ) {
      return 'Recording';
    }

    if (
      state.matches({
        InSession: 'Paused',
      })
    ) {
      return 'Paused';
    }
  });

  const { send } = useTranscriptionMachineActor();

  const endSession = () => {
    send({ type: 'STOP' });
    onEndSession?.();
  };

  return match(state)
    .with('Recording', () => (
      <Box hStack>
        <Button
          onClick={() => {
            captureEvent('paused_session');
            send({ type: 'PAUSE' });
          }}
          variant="outline"
        >
          <PauseCircleIcon className="h-5 sm:mr-2" />
          <span className="hidden sm:block">Pause</span>
        </Button>
        <Button
          onClick={() => {
            captureEvent('ended_session');
            endSession();
          }}
          variant="dark"
        >
          End Session
        </Button>
      </Box>
    ))
    .with('Paused', () => (
      <Box hStack>
        <Button
          onClick={() => {
            captureEvent('resumed_session');
            send({ type: 'RESUME' });
          }}
          variant="outline"
        >
          <PlayCircleIcon className="h-5 sm:mr-2" />
          <span className="hidden sm:block">Resume</span>
        </Button>
        <Button
          onClick={() => {
            captureEvent('ended_session', {
              wasPausedWhenEnded: true,
            });
            endSession();
          }}
          variant="dark"
        >
          End Session
        </Button>
      </Box>
    ))
    .with('Loading', () => (
      <Box hStack>
        <Loader2 className="text-brand-9 mr-2 h-5 animate-spin" />
        Processing
      </Box>
    ))
    .otherwise(() => null);
};
