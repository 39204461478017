import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';

import {
  PageTitle,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  SidebarDefaultTrigger,
  TableSkeleton,
} from '@eluve/components';
import { useCaptureEventOnMount } from '@eluve/posthog-react';

import { TenantOutputTemplatesList } from './TenantOutputTemplatesList';

export interface TenantOutputTemplatesListPageProps {}

export const TenantOutputTemplatesListPage: React.FC<
  TenantOutputTemplatesListPageProps
> = () => {
  useCaptureEventOnMount('page_view_output_templates_list');

  return (
    <>
      <Helmet>
        <title>Templates | Eluve</title>
      </Helmet>
      <ReskinMain>
        <ReskinHeader>
          <SidebarDefaultTrigger />
          <PageTitle>Templates</PageTitle>
        </ReskinHeader>
        <ReskinContent variant="fullWidth">
          <Suspense fallback={<TableSkeleton numRows={20} />}>
            <TenantOutputTemplatesList />
          </Suspense>
        </ReskinContent>
      </ReskinMain>
    </>
  );
};
