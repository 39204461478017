import { graphql } from '@eluve/graphql.tada';

export const searchBillingCodesQuery = graphql(`
  query searchBillingCodes($query: String!, $limit: Int!) {
    searchMedicalCodes(args: { search_term: $query, _limit: $limit }) {
      __typename
      id
      code
      codeType
      description
    }
  }
`);
