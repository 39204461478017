import React from 'react';

import { tv } from '@eluve/components';

const styles = tv({
  base: 'text-title4 inline-flex flex-shrink-0 text-nowrap rounded-md px-2 py-1',
  variants: {
    type: {
      CPT: 'bg-brandTeal text-brandTealContrast',
      ICD_10: 'bg-brandPurple text-brandPurpleContrast',
      ICD_9: 'bg-brandOrange text-brandOrangeConstrast',
      SNOMED: 'border',
    },
  },
});

export type CodeType = 'ICD_10' | 'ICD_9' | 'CPT' | 'SNOMED';

export interface MedicalCodeTypeBadgeProps {
  codeType: CodeType;
  label?: string;
}

export const MedicalCodeTypeBadge = React.forwardRef<
  HTMLDivElement,
  MedicalCodeTypeBadgeProps
>(({ codeType, label }, ref) => {
  const className = styles({ type: codeType });

  return (
    <div ref={ref} className={className}>
      {label ? label : codeType.replace('_', '-')}
    </div>
  );
});
