import React, { useMemo } from 'react';

import { TranscriptWithHighlights } from '@eluve/blocks';
import { Box, P } from '@eluve/components';
import { useAppointmentTranscript } from '@eluve/frontend-appointment-hooks';
import { useBillingCodeStore } from '@eluve/frontend-feature-appointment';
import { toSentences } from '@eluve/utils';

import { useAutoScrollContainer } from '../hooks/useAutoScrollContainer';

export const AppointmentCurrentTranscript: React.FC = () => {
  const transcript = useAppointmentTranscript();
  const containerRef = useAutoScrollContainer([transcript]);

  const formattedTranscript = useMemo(
    () => toSentences(transcript),
    [transcript],
  );

  const highlightedExcerpts = useBillingCodeStore(
    (s) => s.highlightedTranscriptExcerpts,
  );

  return (
    <Box className="h-full overflow-y-auto" ref={containerRef}>
      {transcript ? (
        <div className="text-gray-11 whitespace-pre-line align-baseline font-normal !leading-[2.5rem]">
          <TranscriptWithHighlights
            transcript={formattedTranscript}
            excerpts={highlightedExcerpts ?? []}
          />
        </div>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <P className="text-gray-8">No transcript.</P>
        </div>
      )}
    </Box>
  );
};
