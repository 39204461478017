import { Slot } from '@radix-ui/react-slot';
import React, { ElementType } from 'react';
import { VariantProps } from 'tailwind-variants';

import { tv } from './cn';
import { flexVariants } from './flexVariants';

const vStackVariants = tv({
  base: 'flex-col',
  extend: flexVariants,
  defaultVariants: {
    align: 'start',
    justify: 'start',
    gap: 2,
  },
});

export interface VStackProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof vStackVariants> {
  asChild?: boolean;
  as?: ElementType;
}

const VStack = React.forwardRef<HTMLDivElement, VStackProps>(
  (
    {
      gap,
      align,
      justify,
      wFull,
      className,
      asChild,
      as: Ele = 'div',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : Ele;
    return (
      <Comp
        className={vStackVariants({ gap, align, justify, wFull, className })}
        ref={ref}
        {...props}
      />
    );
  },
);

VStack.displayName = 'VStack';

export { VStack, vStackVariants };
