import { ZodSchema, z } from 'zod';

import { LlmOutputTypesEnum } from '@eluve/graphql-types';
import { stringBooleanSchema } from '@eluve/utils';

import { billingCodeSchema } from './billing-code-schema';
import { chiefComplaintSchema } from './chief-complaint.schema';
import { differentialDiagnosesSchema } from './differential-diagnoses';
import { pfshSchema } from './pfsh-schema';
import { reviewOfSystemsSchema } from './review-of-systems-schema';
import { SOAPSchema } from './soap-note';

export const factVerificationSchema = z.object({
  verdict: stringBooleanSchema,
  evidence: z.string(),
});

export const factGenerationSchema = z.object({
  diffs: z.array(
    z.object({
      category: z.enum(['ADDED', 'EDITED', 'DELETED']),
      llmNote: z.string().describe('Excerpt from the LLM note').nullable(),
      finalNote: z.string().describe('Excerpt from the final note').nullable(),
      facts: z.array(
        z.object({
          statement: z.string(),
          expectedValue: z.boolean(),
        }),
      ),
    }),
  ),
});

export const piiRemovalSchema = z.object({
  redactedText: z.string(),
  redactedFields: z.array(
    z.object({
      excerpt: z.string(),
      category: z.string(),
      explanation: z.string().optional(),
    }),
  ),
});
export const dynamicOutputSchema = z.object({}).passthrough();

export const llmOutputValidators: Record<LlmOutputTypesEnum, ZodSchema<any>> = {
  SOAP_NOTE: SOAPSchema,
  FACT_VERIFICATION: factVerificationSchema,
  CLIENT_RECAP: z.any(),
  BILLING_CODE_RECOMMENDATION: billingCodeSchema,
  DIFFERENTIAL_DIAGNOSES: differentialDiagnosesSchema,
  GENERIC_OUTPUT: z.any(),
  REVIEW_OF_SYSTEMS: reviewOfSystemsSchema,
  PFSH: pfshSchema,
  FACT_GENERATION: factGenerationSchema,
  CHIEF_COMPLAINT: chiefComplaintSchema,
  PII_REMOVAL: piiRemovalSchema,
  DYNAMIC_OUTPUT: dynamicOutputSchema,
};
