import {
  BoxSelect,
  SlidersHorizontal,
  SquareCheckBig,
  Text,
} from 'lucide-react';

import { BlockType } from '@eluve/llm-outputs';

export const BlockTypeIcons: Record<BlockType, React.ElementType> = {
  group: BoxSelect,
  text: Text,
  checkbox: SquareCheckBig,
  range: SlidersHorizontal,
};

export const BlockTypeIcon: React.FC<{
  type: BlockType;
  className?: string;
}> = ({ type, className }) => {
  const Icon = BlockTypeIcons[type];
  return <Icon className={className} />;
};
