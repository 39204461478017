import { useCompleteFragment } from '@eluve/apollo-client';

import { useAppointmentId } from './appointment.context';
import { appointmentLlmOutputTemplateFragment } from './operations';

/**
 * Returns the external template id for the appointment
 * This is used to sync the appointment to the chart template within EHR
 */
export const useExternalTemplateId = () => {
  const appointmentId = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentLlmOutputTemplateFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  return (
    data?.llm_output_template?.external_chart_templates?.[0]
      ?.externalChartTemplateId ?? null
  );
};
