import { useSuspenseQuery } from '@apollo/client';

import { getTenantUserSettingsQuery } from '@eluve/frontend-feature-user-settings';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

export const TenantUserSettings: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();
  useSuspenseQuery(getTenantUserSettingsQuery, {
    variables: { tenantId, userId },
  });
  return children;
};
