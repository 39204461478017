import { z } from 'zod';

export const passwordRequirementMessages = {
  length: 'Must contain at least 8 characters',
  lowercase: 'Must contain at least 1 lowercase character',
  uppercase: 'Must contain at least 1 uppercase character',
  number: 'Must contain at least 1 number',
} as const;

export const passwordRequirements = z.string().superRefine((str, ctx) => {
  if (str.length < 8) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: passwordRequirementMessages.length,
    });
  }

  if (!/[a-z]/.test(str)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: passwordRequirementMessages.lowercase,
    });
  }

  if (!/[A-Z]/.test(str)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: passwordRequirementMessages.uppercase,
    });
  }

  if (!/[0-9]/.test(str)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: passwordRequirementMessages.number,
    });
  }
});
