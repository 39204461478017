import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { Block, BlockType } from '@eluve/llm-outputs';

type BuilderBlockProps = {
  id: string;
  collapsed?: boolean;
  isValid: boolean;
};

export type BuilderBlock = Block & BuilderBlockProps;

export type AsBuilderBlock<T extends Block> = T & BuilderBlockProps;

export type FlattenedBlock = BuilderBlock & {
  parentId: UniqueIdentifier | null;
  parentType: BlockType | null;
  depth: number;
  index: number;
};

export const areAllBlocksValid = (blocks: BuilderBlock[]) => {
  const allTopLevelBlocksValid = blocks.every((block) => block.isValid);

  if (!allTopLevelBlocksValid) {
    return false;
  }

  for (const block of blocks) {
    if (block.type === 'group') {
      const areAllGroupBlocksValid = areAllBlocksValid(
        block.blocks as BuilderBlock[],
      );
      if (!areAllGroupBlocksValid) {
        return false;
      }
    }
  }

  return true;
};

export const flattenBlocks = (blocks: BuilderBlock[]): FlattenedBlock[] => {
  const flattenedBlocks: FlattenedBlock[] = [];

  const flatten = (
    blocks: BuilderBlock[],
    parentId: UniqueIdentifier | null,
    parentType: BlockType | null,
    depth: number,
  ) => {
    blocks.forEach((block, index) => {
      flattenedBlocks.push({ ...block, parentId, parentType, depth, index });

      if (block.type === 'group') {
        flatten(
          block.blocks as BuilderBlock[],
          block.id,
          block.type,
          depth + 1,
        );
      }
    });
  };

  flatten(blocks, null, null, 0);

  return flattenedBlocks;
};

export const removeChildrenOf = (
  blocks: FlattenedBlock[],
  ids: UniqueIdentifier[],
) => {
  const excludeParentIds = [...ids];

  return blocks.filter((block) => {
    if (block.parentId && excludeParentIds.includes(block.parentId)) {
      if (block.type === 'group' && block.blocks.length) {
        excludeParentIds.push(block.id);
      }
      return false;
    }

    return true;
  });
};

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

function getMaxDepth({ previousItem }: { previousItem: FlattenedBlock }) {
  if (previousItem && previousItem.type === 'group') {
    return previousItem.depth + 1;
  }

  if (previousItem && previousItem.parentType === 'group') {
    return previousItem.depth;
  }

  return 0;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedBlock }) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

export const getProjection = (
  blocks: FlattenedBlock[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
) => {
  const overItemIndex = blocks.findIndex(({ id }) => id === overId);
  const activeItemIndex = blocks.findIndex(({ id }) => id === activeId);
  const activeItem = blocks[activeItemIndex];
  const newItems = arrayMove(blocks, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1]!;
  const nextItem = newItems[overItemIndex + 1]!;
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem!.depth + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem,
  });

  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return { depth, maxDepth, minDepth, parentId: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
  }
};

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);
