import format from 'date-fns/format';

/**
 * Returns a date in the format YYYY-MM-DD for use in URLs when only the date portion needs to be conveyed
 * @returns
 */
export const urlSafeDate = (date?: Date) =>
  format(date ?? new Date(), 'yyyy-MM-dd');

/**
 * Converts a date string in the format YYYY-MM-DD to a Date object
 * @param input
 * @returns
 */
export const convertUrlStringDate = (input: string) =>
  new Date(input.replaceAll('-', '/'));
