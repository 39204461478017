import { Howl } from 'howler';
import fromPairs from 'lodash/fromPairs';

import alertSoundFile from './sounds/alert.mp3';
import pauseStopSoundFile from './sounds/pausestop.mp3';
import startSoundFile from './sounds/start.mp3';

type SessionSounds = 'start' | 'pauseStop' | 'alert' | 'disclaimer';

class SoundPlayer<T extends string> {
  private volume = 0.5;
  private soundRecords: Record<T, Howl> = {} as Record<T, Howl>;
  private isEnabled = true;

  constructor(sounds: Record<T, string>) {
    this.soundRecords = fromPairs(
      Object.entries(sounds).map(([key, value]) => [
        key,
        new Howl({
          src: [value as string],
          volume: this.volume,
        }),
      ]),
    ) as Record<T, Howl>;
  }

  play = (name: T) => {
    if (this.isEnabled) {
      this.soundRecords[name]?.play();
    }
  };

  setIsEnabled = (isEnabled: boolean) => {
    this.isEnabled = isEnabled;
  };

  on = (name: T, event: string, callback: () => void) => {
    this.soundRecords[name]?.on(event, callback);
  };
}

export const sessionSoundPlayer = new SoundPlayer<SessionSounds>({
  // https://www.zapsplat.com/music/high-tech-beep-with-reverb-5/
  start: startSoundFile,
  // https://www.zapsplat.com/music/high-tech-cyber-beep-7/
  pauseStop: pauseStopSoundFile,
  // https://www.zapsplat.com/music/alert-chime-short-musical-notification-error-1/
  alert: alertSoundFile,

  disclaimer: alertSoundFile,
});
