import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import { LlmOutputTypesLookup } from '@eluve/graphql-types';
import { useNamedLogger } from '@eluve/logger';
import { useIsPostHogFeatureFlagEnabled } from '@eluve/posthog-react';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import {
  enabledTenantLlmOutputTemplateFragment,
  getTenantUserSettingsQuery,
  medicalNotesTemplatesFragment,
  tenantUserSettingsFragment,
} from './operations';

export const usePromptTemplates = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();
  const logger = useNamedLogger('usePromptTemplates');

  // Fetch updates in the background while rendering immediately from
  // the cache
  const { data } = useQuery(getTenantUserSettingsQuery, {
    variables: {
      tenantId,
      userId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const dynamicOutputTemplatesEnabled = useIsPostHogFeatureFlagEnabled(
    'USE_DYNAMIC_OUTPUT_PROMPTS',
  );

  const { activePromptTemplates: unfilteredPromptTemplates } =
    useCompleteFragment({
      fragment: medicalNotesTemplatesFragment,
      key: QUERY_ROOT_ID,
    });

  const tenantUserSettings = useCompleteFragment({
    fragment: tenantUserSettingsFragment,
    key: { tenantId, userId },
  });
  const usersOutputTemplates =
    tenantUserSettings?.users_llm_output_templates.map(
      (t) => t.llm_output_template,
    );

  const enabledTenantLlmOutputTemplates = useCompleteFragment({
    fragment: enabledTenantLlmOutputTemplateFragment,
    key: { id: tenantId },
  });

  const enabledTenantOutputTemplates =
    enabledTenantLlmOutputTemplates.tenant_llm_output_templates?.map(
      (t) => t.llm_output_template,
    );

  const activeOutputTemplates = [
    ...usersOutputTemplates,
    ...enabledTenantOutputTemplates,
  ];

  const activeSOAPPromptTemplates = (unfilteredPromptTemplates ?? []).filter(
    (template) => {
      if (template.outputType === LlmOutputTypesLookup.DYNAMIC_OUTPUT) {
        return (
          dynamicOutputTemplatesEnabled && Boolean(activeOutputTemplates.length)
        );
      }
      return true;
    },
  );

  const userSettingsPromptTemplateId =
    data?.tenantUsersByPk?.settings?.promptTemplateId;
  const userSettingsOutputTemplateId =
    data?.tenantUsersByPk?.settings?.llmOutputTemplateId;

  let defaultOutputTemplateId = null;

  useEffect(() => {
    if (!usersOutputTemplates.length && !enabledTenantOutputTemplates.length) {
      logger.warn('No output templates available for user');
    }
  }, [usersOutputTemplates, enabledTenantOutputTemplates, logger]);

  useEffect(() => {
    if (!activeSOAPPromptTemplates.length) {
      logger.warn('No prompt templates available for user');
    }
  }, [activeSOAPPromptTemplates, logger]);

  if (activeOutputTemplates.length) {
    const userDefaultTemplate = activeOutputTemplates.find(
      (t) => t.id === userSettingsOutputTemplateId,
    );

    defaultOutputTemplateId = userDefaultTemplate
      ? userDefaultTemplate.id
      : activeOutputTemplates[0]?.id;
  }

  // Prompt Template ID
  let defaultPromptTemplateId = null;

  if (activeSOAPPromptTemplates.length) {
    const userDefaultTemplate = activeSOAPPromptTemplates.find(
      (t) => t.id === userSettingsPromptTemplateId,
    );

    defaultPromptTemplateId =
      userDefaultTemplate?.id ??
      activeSOAPPromptTemplates.find((t) => t.isCurrentDefault)?.id ??
      activeSOAPPromptTemplates[0]?.id ??
      null;
  }

  return {
    activeSOAPPromptTemplates,
    defaultPromptTemplateId,
    defaultOutputTemplateId,
    userSettingsPromptTemplateId,
    activeOutputTemplates,
    userSettingsOutputTemplateId,
  };
};
