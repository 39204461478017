import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import { TooltipLabel } from '@eluve/blocks';
import { ColDefBuilder, DataTable, EluveLogo } from '@eluve/components';
import { getTenantOutputTemplatesQuery } from '@eluve/frontend-feature-prompt-templates';
import { VendorTypesEnum } from '@eluve/graphql-types';
import { getVendorProvider } from '@eluve/vendor';

type OutputTemplateRow = {
  id: string;
  name: string;
  description: string | null;
  createdAt: string;
  source: VendorTypesEnum | 'Eluve';
  sourceArgs?: {
    domain: string;
    externalChartTemplateId: string;
    externalChartTemplateOwnerId: string;
  };
};

const SourceCell: React.FC<{
  source: VendorTypesEnum | 'Eluve';
  sourceArgs?: {
    domain: string;
    externalChartTemplateId: string;
    externalChartTemplateOwnerId: string;
  };
}> = ({ source, sourceArgs }) => {
  if (source === 'Eluve') {
    return <EluveLogo />;
  }

  const vendorProvider = getVendorProvider(source);
  const logoData = vendorProvider.getLogo();

  if (vendorProvider.getChartTemplateUrl && sourceArgs) {
    const { domain, externalChartTemplateId, externalChartTemplateOwnerId } =
      sourceArgs;
    const url = vendorProvider.getChartTemplateUrl({
      domain,
      externalChartTemplateOwnerId,
      externalChartTemplateId,
    });
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img className="w-10" src={logoData} alt={`${source} logo`} />
      </a>
    );
  } else {
    return <img className="w-10" src={logoData} alt={`${source} logo`} />;
  }
};

const columns = new ColDefBuilder<OutputTemplateRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .colDef({
    header: 'Source',
    cell: ({
      row: {
        original: { source, sourceArgs },
      },
    }) => {
      return (
        <TooltipLabel label={source}>
          <div>
            <SourceCell source={source} sourceArgs={sourceArgs} />
          </div>
        </TooltipLabel>
      );
    },
  })
  .defaultSortable('description')
  .dateSortable('createdAt', 'Created At')
  .build();

export interface TenantOutputTemplatesListProps {}

export const TenantOutputTemplatesList: React.FC<
  TenantOutputTemplatesListProps
> = () => {
  const { data } = useSuspenseQuery(getTenantOutputTemplatesQuery);

  const rows = useMemo(() => {
    const { externalEhr, llmOutputTemplates } = data;
    return llmOutputTemplates.map<OutputTemplateRow>((template) => {
      const ehr = externalEhr?.find(
        (e) => e.id === template.external_chart_templates[0]?.externalEhrId,
      );
      const vendor = ehr?.vendor;
      const sourceArgs =
        vendor &&
        template.external_chart_templates[0]?.externalChartTemplateId &&
        template.external_chart_templates[0]?.externalChartTemplateOwnerId
          ? {
              domain: ehr.domain,
              externalChartTemplateId:
                template.external_chart_templates[0]?.externalChartTemplateId,
              externalChartTemplateOwnerId:
                template.external_chart_templates[0]
                  ?.externalChartTemplateOwnerId,
            }
          : undefined;
      return {
        id: template.id,
        name: template.name,
        description: template.description,
        createdAt: template.createdAt,
        sourceArgs,
        source: vendor ?? 'Eluve',
      };
    });
  }, [data]);

  return (
    <DataTable
      columns={columns}
      data={rows}
      enableGlobalSearch
      initialSortingState={[
        {
          id: 'createdAt',
          desc: true,
        },
      ]}
    />
  );
};
