import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { SessionSchema } from '@eluve/api-contract';

/**
 * Provides access to the parsed session data for the currently
 * authenticated user. If the session is not available, an error will be thrown
 */
export const useSession = () => {
  const session = useSessionContext();
  if (session.loading || !session.doesSessionExist) {
    throw new Error('Attempted to access session but it is not available');
  }

  const parsedSession = SessionSchema.parse(session.accessTokenPayload);
  return parsedSession;
};

export const useUserIdFromSession = () => {
  const session = useSession();
  return session['https://hasura.io/jwt/claims']['x-hasura-user-id'];
};

/**
 * We should only be using this function whenever we do not know which tenant user is part of.
 * In most cases, we should use tenantID from params
 */
export const useTenantIdFromSession = () => {
  const session = useSession();
  return session['https://hasura.io/jwt/claims']['x-hasura-tenant-id'];
};

export const useRolesFromSession = () => {
  const session = useSession();
  return session['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
};

export const useIsTenantAdminFromSession = () => {
  const roles = useRolesFromSession();
  return roles.includes('tenant-admin');
};

export const useUserDisplayFromSession = () => {
  const session = useSession();
  return {
    email: session.email,
    userInitials: `${session.firstName?.[0] ?? ''}${
      session.lastName?.[0] ?? ''
    }`.toUpperCase(),
    firstName: session.firstName,
  };
};

export const useIsEluveAdmin = () => {
  const session = useSession();
  return session.isEluveAdmin;
};
