import { useMutation } from '@apollo/client';
import { UserIcon } from 'lucide-react';
import { toast } from 'sonner';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import {
  Box,
  DetailsSidebar,
  H4,
  SingleDateSelector,
  TimeRangeSelect,
} from '@eluve/components';
import {
  AppointmentPatientIdFragment,
  useAppointmentStatus,
  useIsAppointmentReadonly,
} from '@eluve/frontend-appointment-hooks';
import { SessionTypeSelector } from '@eluve/frontend-feature-appointment';
import { VariablesOf, graphql } from '@eluve/graphql.tada';
import {
  FeatureFlaggedComponent,
  LocationSelector,
  PatientSelector,
} from '@eluve/smart-blocks';

import { useUserName } from '../../../hooks/useUserName';
import { EhrDataImporter } from '../../components/EhrDataImporter';
import { SignAndSyncAppointmentButton } from '../SignAndSyncAppointmentButton';

import { AppointmentLastSaved } from './AppointmentLastSaved';
import { AppointmentPDFOutputControls } from './AppointmentPDFOutputControls';

interface AppointmentDetailsProps {
  tenantId: string;
  appointmentId: string;
}

export const AppointmentDetailsFragment = graphql(`
  fragment AppointmentDetails on Appointments @_unmask {
    __typename
    id
    startedAt
    startDate
    endDate
    locationId
    patientId
    userId
    inputLanguage
    outputLanguage
  }
`);

const UpdateAppointmentDetailsMutation = graphql(
  `
    mutation UpdateAppointmentDetails(
      $tenantId: uuid!
      $id: uuid!
      $input: AppointmentsSetInput!
    ) {
      updateAppointmentsByPk(
        pkColumns: { tenantId: $tenantId, id: $id }
        _set: $input
      ) {
        ...AppointmentDetails
      }
    }
  `,
  [AppointmentDetailsFragment],
);

const toDate = (date?: string | null) => (date ? new Date(date) : undefined);
const dateToISOString = (date?: Date | null) =>
  date ? date.toISOString() : null;

export const AppointmentDetails: React.FC<AppointmentDetailsProps> = ({
  tenantId,
  appointmentId,
}) => {
  const isReadonly = useIsAppointmentReadonly();

  const appointmentDetails = useCompleteFragment({
    fragment: AppointmentDetailsFragment,
    key: {
      id: appointmentId,
    },
  });

  const username = useUserName(appointmentDetails?.userId ?? '');
  const status = useAppointmentStatus();

  const [updateAppointmentDetails] = useMutation(
    UpdateAppointmentDetailsMutation,
    {
      optimisticResponse: ({ input, id }) => {
        return {
          ___typename: 'mutation_root' as const,
          updateAppointmentsByPk: {
            ...appointmentDetails,
            ...input,
            startDate: input.startDate ?? appointmentDetails.startDate,
            inputLanguage: appointmentDetails.inputLanguage ?? '',
            outputLanguage: appointmentDetails.outputLanguage ?? '',
            id,
          },
        };
      },
      onError: () => {
        toast.error('Unable to update appointment details');
      },
    },
  );

  const handleChange = (
    input: VariablesOf<typeof UpdateAppointmentDetailsMutation>['input'],
  ) => {
    updateAppointmentDetails({
      variables: {
        tenantId,
        id: appointmentId,
        input,
      },
    });
  };

  return (
    <DetailsSidebar className="w-full max-w-[400px] px-2 sm:w-auto">
      <Box vStack className="h-full p-0">
        <PatientSelector
          disabled={isReadonly}
          tenantId={tenantId}
          onPatientSelected={(patient) =>
            handleChange({ patientId: patient?.id ?? null })
          }
          onOptimisticUpdate={(patientId) => {
            cacheUtils.updateFragment(
              {
                fragment: AppointmentPatientIdFragment,
                key: { id: appointmentId },
              },
              (existing) => {
                if (!existing) {
                  return;
                }
                return {
                  ...existing,
                  patientId,
                };
              },
            );
          }}
        />
        <Box vStack className="w-full gap-4 px-4 pt-4">
          <H4>Appointment</H4>
          <Box className="grid w-full grid-cols-[auto] items-center gap-x-6 gap-y-2 text-sm sm:grid-cols-[max-content_1fr]">
            <span className="text-gray-10">Date</span>
            <SingleDateSelector
              disabled={isReadonly}
              date={toDate(appointmentDetails?.startDate)}
              onDateChange={(newDate) =>
                handleChange({ startDate: dateToISOString(newDate) })
              }
              variant="subtle"
            />

            <span className="text-gray-10">Time</span>
            <TimeRangeSelect
              disabled={isReadonly}
              date={toDate(appointmentDetails?.startDate)}
              startTime={toDate(appointmentDetails?.startDate)}
              endTime={toDate(appointmentDetails?.endDate)}
              onStartTimeChange={(newTime) => {
                handleChange({ startDate: dateToISOString(newTime) });
              }}
              onEndTimeChange={(newTime) =>
                handleChange({ endDate: dateToISOString(newTime) })
              }
              variant="subtle"
            />

            <span className="text-gray-10">Location</span>
            <LocationSelector
              disabled={isReadonly}
              tenantId={tenantId}
              selectedLocationId={appointmentDetails?.locationId ?? undefined}
              onLocationSelected={(location) =>
                handleChange({ locationId: location?.id ?? null })
              }
              variant="subtle"
            />

            <FeatureFlaggedComponent flag="SESSION_TYPES">
              <span className="text-gray-10">Type</span>
              <SessionTypeSelector disabled={isReadonly} />
            </FeatureFlaggedComponent>

            <span className="text-gray-10">Created by</span>
            <Box
              hStack
              className="w-full overflow-hidden px-2"
              title={username}
            >
              <UserIcon size={16} />
              <p className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm">
                {username}
              </p>
            </Box>
          </Box>
        </Box>

        {status !== 'NOT_STARTED' && (
          <>
            <div className="flex-1" />
            <EhrDataImporter onlyShowIfError />
            <div className="border-gray-4 w-full border-b" />
            <AppointmentLastSaved />
            <Box hStack fullWidth>
              <AppointmentPDFOutputControls />
              <SignAndSyncAppointmentButton />
            </Box>
          </>
        )}
      </Box>
    </DetailsSidebar>
  );
};
