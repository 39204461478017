import { ChevronRightIcon, HomeIcon } from 'lucide-react';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useTenantName } from '@eluve/smart-blocks';

import { FactVerifactionBreadcrumbs } from './FactVerifactionBreadcrumbs';
import { OutputTemplateBreadcrumbs } from './OutputTemplateBreadcrumbs';
import { PromptBreadCrumbs } from './PromptBreadcrumbs';

export interface AdminBreadcrumbsProps {}

const TenantBreadCrumbs: React.FC<{
  tenantId: string;
  appointmentId?: string;
}> = ({ tenantId, appointmentId }) => {
  const location = useLocation();

  const isTenantRoute = location.pathname.endsWith(tenantId);
  const tenantName = useTenantName(tenantId);
  return (
    <>
      {!isTenantRoute && (
        <li>
          <div className="flex items-center">
            <ChevronRightIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <Link
              to={`/admin/tenant/${tenantId}`}
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              {tenantName}
            </Link>
          </div>
        </li>
      )}
      {Boolean(appointmentId) && (
        <li>
          <div className="flex items-center">
            <ChevronRightIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <Link
              to={`/admin/tenant/${tenantId}/appointments`}
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              Appointments
            </Link>
          </div>
        </li>
      )}
    </>
  );
};

export const AdminBreadcrumbs: React.FC<AdminBreadcrumbsProps> = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId?: string;
  };

  const location = useLocation();
  const isAdminRoot = location.pathname.endsWith('/admin');
  const isPrompts = location.pathname.includes('prompts');
  const isFactVerification = location.pathname.includes('fact-verification');
  const isOutputTemplates = location.pathname.includes('output-templates');

  if (isAdminRoot) {
    return null;
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link
              to="/admin"
              className="flex gap-2 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span>Admin Home</span>
            </Link>
          </div>
        </li>
        {tenantId && (
          <TenantBreadCrumbs
            tenantId={tenantId}
            appointmentId={appointmentId}
          />
        )}
        {isPrompts && <PromptBreadCrumbs />}
        {isFactVerification && <FactVerifactionBreadcrumbs />}
        {isOutputTemplates && <OutputTemplateBreadcrumbs />}
      </ol>
    </nav>
  );
};
