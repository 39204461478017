import format from 'date-fns/format';
import {
  FileAudio,
  FlaskConical,
  HeadsetIcon,
  LogOutIcon,
  MailIcon,
  MessageCircleCode,
  ShieldCheck,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { redirectToAuth } from 'supertokens-auth-react';
import Session from 'supertokens-auth-react/recipe/session';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  P,
  tv,
} from '@eluve/components';
import {
  useSession,
  useTenantIdFromParams,
  useUserDisplayFromSession,
} from '@eluve/session-helpers';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

import { appConfig } from '../../../../config';

const dropdownItemVariant = tv({
  base: 'focus:bg-brand-2 focus:text-brand-9 cursor-pointer',
});

export const UserHeaderDropdownMenu = () => {
  const tenantId = useTenantIdFromParams();
  const { email, userInitials } = useUserDisplayFromSession();
  const session = useSession();
  const { isEluveAdmin, isPromptTemplateCreator, isEluveEvalUser } = session;

  const logoutClicked = async () => {
    await Session.signOut();
    redirectToAuth();
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="bg-gray-12 flex h-9 items-center justify-center rounded-sm px-2 font-medium text-white">
          {userInitials}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="mr-2 mt-4 border-0 p-4"
        data-side="bottom"
      >
        <DropdownMenuItem className="pointer-events-none">
          <P className="font-normal italic">
            App Version: {appConfig.VITE_APP_VERSION}
          </P>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="bg-gray-2 pointer-events-none">
          <MailIcon className="mr-2 size-4" />
          <span className="font-normal">{email}</span>
        </DropdownMenuItem>

        <FeatureFlaggedComponent flag="LOCAL_FILES_MANAGEMENT">
          <Link to={`/tenants/${tenantId}/files`}>
            <DropdownMenuItem className={dropdownItemVariant()}>
              <FileAudio className="mr-2 size-4" />
              Device Files
            </DropdownMenuItem>
          </Link>
        </FeatureFlaggedComponent>
        <a
          href={`mailto:feedback@eluve.com?subject=Eluve Feedback - ${format(Date.now(), 'yyyy-MM-dd')}`}
        >
          <DropdownMenuItem className={dropdownItemVariant()}>
            <HeadsetIcon className="mr-2 size-4" />
            Contact Us
          </DropdownMenuItem>
        </a>

        {(isPromptTemplateCreator || isEluveEvalUser) && (
          <>
            <DropdownMenuSeparator />
            {isPromptTemplateCreator && (
              <Link to="/prompts">
                <DropdownMenuItem className={dropdownItemVariant()}>
                  <MessageCircleCode className="mr-2 h-4 w-4" />
                  <span>Prompts</span>
                </DropdownMenuItem>
              </Link>
            )}

            {isEluveEvalUser && (
              <Link to="/eval/source-artifact-review">
                <DropdownMenuItem className={dropdownItemVariant()}>
                  <FlaskConical className="mr-2 h-4 w-4" />
                  <span>Eval</span>
                </DropdownMenuItem>
              </Link>
            )}
          </>
        )}
        {isEluveAdmin && (
          <>
            <DropdownMenuSeparator />
            <Link to="/admin">
              <DropdownMenuItem className="cursor-pointer bg-yellow-500 hover:bg-yellow-600">
                <ShieldCheck className="mr-2 h-4 w-4" />
                <span>Admin</span>
              </DropdownMenuItem>
            </Link>
          </>
        )}

        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="cursor-pointer text-red-500 focus:bg-red-100 focus:text-red-500"
          onClick={logoutClicked}
        >
          <LogOutIcon className="mr-2 size-4" />
          Log Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
