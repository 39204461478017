import { graphql } from '@eluve/graphql.tada';

export const llmOutputTemplateFragment = graphql(`
  fragment LlmOutputTemplate on LlmOutputTemplates @_unmask {
    __typename
    id
    name
    description
    dynamicArtifactTemplate
    isActive
    llmOutputType
  }
`);

export const getOutputTemplateQuery = graphql(
  `
    query getOutputTemplate($id: uuid!) {
      llmOutputTemplatesByPk(id: $id) {
        ...LlmOutputTemplate
      }
    }
  `,
  [llmOutputTemplateFragment],
);

export const createOutputTemplateMutation = graphql(`
  mutation createOutputTemplate($input: LlmOutputTemplatesInsertInput!) {
    insertLlmOutputTemplatesOne(object: $input) {
      __typename
      id
    }
  }
`);

export const updateOutputTemplateMutation = graphql(
  `
    mutation updateOutputTemplate(
      $id: uuid!
      $name: String!
      $description: String!
      $dynamicArtifactTemplate: jsonb!
    ) {
      updateLlmOutputTemplatesByPk(
        pkColumns: { id: $id }
        _set: {
          name: $name
          description: $description
          dynamicArtifactTemplate: $dynamicArtifactTemplate
        }
      ) {
        ...LlmOutputTemplate
      }
    }
  `,
  [llmOutputTemplateFragment],
);
