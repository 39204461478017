import React from 'react';

import {
  PageTitle,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  SidebarDefaultTrigger,
} from '@eluve/components';

import { OutputTemplateDetailsPage } from '../../../output-templates/OutputTemplateDetailsPage';

export const TenantOutputTemplateDetailsPage: React.FC = () => {
  return (
    <ReskinMain>
      <ReskinHeader>
        <SidebarDefaultTrigger />
        <PageTitle>Template Details</PageTitle>
      </ReskinHeader>
      <ReskinContent variant="container">
        <OutputTemplateDetailsPage showJsonSchema={false} />
      </ReskinContent>
    </ReskinMain>
  );
};
