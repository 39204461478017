import { useMutation } from '@apollo/client';
import { PlusIcon } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { getAdminTenantsSummaryQuery } from '../query';

const NewTenantMutation = graphql(`
  mutation newTenant($tenant: TenantsInsertInput!) {
    insertTenantsOne(object: $tenant) {
      __typename
      id
      name
      createdAt
      isPendingApproval
      appointmentsAggregate {
        aggregate {
          count
        }
      }
      tenantUsersAggregate {
        aggregate {
          count
        }
      }
    }
  }
`);

export const NewTenantAction = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newTenantName, setNewTenantName] = useState('');

  const [insertTenant] = useMutation(NewTenantMutation, {
    onCompleted: (data) => {
      const newTenant = data?.insertTenantsOne;
      if (!newTenant) return;
      toast.success('New Tenant added successfully');

      setIsDialogOpen(false);
    },
    onError: () => {
      toast.error('Failed to add new tenant');
    },
    update: (cache, { data }) => {
      const newTenant = data?.insertTenantsOne;
      if (!newTenant) return;
      cache.updateQuery({ query: getAdminTenantsSummaryQuery }, (queryData) => {
        if (!queryData) return queryData;
        return {
          tenants: [...queryData.tenants, newTenant],
        };
      });
    },
  });

  const handleDialogOpen = (open: boolean) => {
    if (open) {
      setNewTenantName('');
      setIsDialogOpen(open);
      return;
    }

    if (
      newTenantName &&
      !window.confirm('Are you sure you want to discard changes?')
    ) {
      return;
    }

    setIsDialogOpen(false);
  };

  const handleAddTenant = () => {
    insertTenant({
      variables: {
        tenant: {
          name: newTenantName,
          isPendingApproval: false,
        },
      },
    });
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={handleDialogOpen} modal>
      <DialogTrigger asChild>
        <Button>
          <PlusIcon />
          New Tenant
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Tenant</DialogTitle>
        </DialogHeader>
        <Input
          placeholder="Enter new tenant name"
          value={newTenantName}
          onChange={(e) => setNewTenantName(e.target.value)}
        />
        <DialogFooter>
          <Button variant="outline" onClick={() => handleDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleAddTenant}>Add</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
