import { useEffect, useRef } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  useAppointmentActivity,
  useAppointmentId,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

const LatestSummaryNoteUpdatedAtFragment = graphql(`
  fragment LatestHumanSOAPNote on Appointments @_unmask {
    __typename
    id
    humanOutputs(
      where: { output: { outputType: { _eq: SOAP_NOTE } } }
      limit: 1
      orderBy: { output: { updatedAt: DESC } }
    ) {
      __typename
      humanOutputId
      appointmentId
      output {
        __typename
        id
        updatedAt
      }
    }
  }
`);

export const useSummarizationInProgress = () => {
  const appointmentId = useAppointmentId();
  const { isSummarizationInProgress } = useAppointmentActivity();
  const previousSummaryUpdatedAt = useRef<string | undefined>(undefined);

  const appointment = useCompleteFragment({
    fragment: LatestSummaryNoteUpdatedAtFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  const latestSummaryUpdatedAt =
    appointment?.humanOutputs?.[0]?.output?.updatedAt;

  useEffect(() => {
    if (isSummarizationInProgress) {
      previousSummaryUpdatedAt.current = latestSummaryUpdatedAt;
    }
  }, [isSummarizationInProgress, latestSummaryUpdatedAt]);

  const isNewSummaryIsNotUpdated =
    previousSummaryUpdatedAt.current &&
    previousSummaryUpdatedAt.current === latestSummaryUpdatedAt;

  return isSummarizationInProgress || isNewSummaryIsNotUpdated;
};
