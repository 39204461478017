import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const transcriptionContract = c.router(
  {
    batchTranscribe: {
      method: 'POST',
      path: '/batch-transcribe',
      responses: {
        200: z.object({}),
      },
      body: z.object({
        appointmentId: z.string(),
      }),
      summary: 'Schedules a batch transcription job for the given appointment',
    },
  },
  {
    pathPrefix: '/:tenantId/transcription',
  },
);
