import { useCompleteFragment } from '@eluve/apollo-client';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import { tenantUserSettingsFragment } from './operations';

export const useTenantUserSettings = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();

  const { settings } =
    useCompleteFragment({
      fragment: tenantUserSettingsFragment,
      key: {
        tenantId,
        userId,
      },
      strict: false,
    }) ?? {};

  return { settings };
};
