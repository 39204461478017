import { useQuery } from '@apollo/client';
import add from 'date-fns/add';

import { convertUrlStringDate, urlSafeDate } from '@eluve/date-utils';
import { graphql } from '@eluve/graphql.tada';
import { useUserIdFromSession } from '@eluve/session-helpers';
import { useTenantIdFromParams } from '@eluve/session-helpers';

const getAppointmentCounts = graphql(`
  query getAppointmentCounts(
    $min: timestamptz!
    $max: timestamptz!
    $userId: uuid!
    $tenantId: uuid!
  ) {
    upcomingAppointments: appointmentsAggregate(
      where: {
        _and: [
          { startDate: { _gte: $min } }
          { startDate: { _lt: $max } }
          { status: { _neq: CANCELLED } }
          { userId: { _eq: $userId } }
          { tenantId: { _eq: $tenantId } }
        ]
      }
    ) {
      __typename
      aggregate {
        count
      }
    }
    totalAppointments: appointmentsAggregate(
      where: {
        _and: [{ userId: { _eq: $userId } }, { tenantId: { _eq: $tenantId } }]
      }
    ) {
      __typename
      aggregate {
        count
      }
    }
  }
`);

type Args = { days?: number; fromDate?: string };

export const useAppointmentCounts = ({
  days = 7,
  fromDate = urlSafeDate(),
}: Args = {}) => {
  const userId = useUserIdFromSession();
  const tenantId = useTenantIdFromParams();
  const date = convertUrlStringDate(fromDate);

  const {
    data: appointmentCountData,
    refetch,
    loading,
  } = useQuery(getAppointmentCounts, {
    variables: {
      min: date.toISOString(),
      max: add(date, { days }).toISOString()!,
      userId,
      tenantId: tenantId!,
    },
    skip: !tenantId,
  });

  const upcomingAppointmentsCount =
    appointmentCountData?.upcomingAppointments?.aggregate?.count;

  const totalAppointmentsCount =
    appointmentCountData?.totalAppointments?.aggregate?.count;

  return {
    upcomingAppointmentsCount,
    refetch,
    loading,
    totalAppointmentsCount,
  };
};
