import { useDebounce } from '@uidotdev/usehooks';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLatest } from 'react-use';
import { match } from 'ts-pattern';

import { getDepthTextClass } from '@eluve/blocks';
import { Box, Checkbox, P, Slider, textStyles } from '@eluve/components';
import { EluveEditor } from '@eluve/eluve-editor';
import {
  Block,
  CheckboxBlock,
  GroupBlock,
  RangeBlock,
  TextFieldBlock,
} from '@eluve/llm-outputs';
import {
  JsonValue,
  areStringsEqualWithoutSpecialCharacters,
} from '@eluve/utils';

const DEBOUNCE_MS = 500;

export type DynamicArtifactSaveContextProps = {
  saveBlock: (blockPath: string[], value: JsonValue) => Promise<void>;
  onPendingChanges: () => void;
};

export const defaultArtifactSaveContextProps: DynamicArtifactSaveContextProps =
  {
    saveBlock: async () => noop(),
    onPendingChanges: () => noop(),
  };

const DynamicArtifactSaveContext =
  createContext<DynamicArtifactSaveContextProps>(
    defaultArtifactSaveContextProps,
  );

export const useSaveBlock = () => {
  const context = useContext(DynamicArtifactSaveContext);
  if (!context) {
    throw new Error(
      'useDynamicArtifactSave must be used within an DynamicArtifactSaveContextProvider',
    );
  }

  return context.saveBlock;
};

const useDebounceSaveBlock = <
  T extends string | number | boolean | { label: string; isChecked: boolean }[],
>(
  block: Block,
  currentValue: T,
) => {
  const { key } = block;
  const { keyPath } = useArtifactContext();
  const { onPendingChanges } = useContext(DynamicArtifactSaveContext);
  const [value, setValue] = useState<T>(currentValue);
  const debouncedValue = useDebounce(value, DEBOUNCE_MS);
  const latestValue = useLatest(currentValue);
  const saveBlock = useSaveBlock();

  useEffect(() => {
    if (currentValue !== value) {
      onPendingChanges();
    }
  }, [currentValue, value, onPendingChanges]);

  useEffect(() => {
    if (debouncedValue !== latestValue.current) {
      saveBlock([...keyPath, key], debouncedValue);
    }
  }, [debouncedValue, key, keyPath, latestValue, saveBlock]);

  return [value, setValue] as const;
};

const getTitleSize = (depth: number) =>
  match(depth + 1)
    .with(1, 2, 3, 4, (depth) => depth)
    .otherwise(() => 4 as const);

const ArtifactContext = createContext<
  { isReadonly: boolean; groupDepth: number; keyPath: string[] } | undefined
>(undefined);

const useArtifactContext = () => {
  const context = useContext(ArtifactContext);
  if (!context) {
    throw new Error(
      'useArtifactContext must be used within an ArtifactContextProvider',
    );
  }
  return context;
};

const TextFieldBlockRenderer: React.FC<{ textField: TextFieldBlock }> = ({
  textField,
}) => {
  const { groupDepth, isReadonly } = useArtifactContext();
  const { label, text } = textField;

  const [_, setTextValue] = useDebounceSaveBlock(textField, text ?? '');

  const textClass = getDepthTextClass(groupDepth + 1);

  return (
    <Box vStack className="w-full">
      {label && <P className={textClass}>{label}</P>}
      <div className="w-full rounded-lg border">
        <EluveEditor
          key={`${textField.key}-${isReadonly}`}
          disabled={isReadonly}
          content={text}
          onUpdate={(editor) => {
            const content = editor?.storage.markdown.getMarkdown();
            if (areStringsEqualWithoutSpecialCharacters(content, text)) {
              return;
            }
            setTextValue(content);
          }}
        />
      </div>
    </Box>
  );
};

const CheckboxBlockRenderer: React.FC<{ checkbox: CheckboxBlock }> = ({
  checkbox,
}) => {
  const { groupDepth, isReadonly } = useArtifactContext();
  const { label, options } = checkbox;
  const [checkboxOptions, setCheckboxOptions] = useDebounceSaveBlock(
    checkbox,
    options,
  );

  const textClass = getDepthTextClass(groupDepth + 1);

  return (
    <Box vStack className="w-full">
      {label && <P className={textClass}>{label}</P>}
      <Box vStack>
        {checkboxOptions.map(({ isChecked, label }, i) => (
          <Box hStack key={i}>
            <Checkbox
              defaultChecked={isChecked}
              disabled={isReadonly}
              onCheckedChange={(checked) => {
                if (typeof checked === 'boolean') {
                  setCheckboxOptions((prev) => {
                    const newOptions: typeof prev = [];
                    for (const option of prev) {
                      if (option.label === label) {
                        newOptions.push({
                          label: option.label,
                          isChecked: checked,
                        });
                      } else {
                        newOptions.push(option);
                      }
                    }
                    return newOptions;
                  });
                }
              }}
            />
            <label className={textStyles.body({ size: 's', weight: 'medium' })}>
              {label}
            </label>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const RangeRenderer: React.FC<{ range: RangeBlock }> = ({ range }) => {
  const { groupDepth, isReadonly } = useArtifactContext();
  const { max, min, step, value, label } = range;

  const [rangeValue, setRangeValue] = useDebounceSaveBlock(range, value ?? 0);

  const textClass = getDepthTextClass(groupDepth + 1);

  return (
    <Box vStack className="w-full">
      {label && <P className={textClass}>{label}</P>}

      <Slider
        min={min}
        max={max}
        value={[rangeValue]}
        step={step}
        disabled={isReadonly}
        showValue="inside"
        onValueChange={([v]) => {
          if (!isNil(v) && !isReadonly) {
            setRangeValue(v);
          }
        }}
      />
    </Box>
  );
};

const GroupRenderer: React.FC<{ group: GroupBlock }> = ({ group }) => {
  const { blocks, label } = group;
  const { groupDepth, isReadonly, keyPath } = useArtifactContext();

  const textClass = getDepthTextClass(groupDepth + 1);

  const contextValue = useMemo(() => {
    return {
      groupDepth: groupDepth + 1,
      isReadonly,
      keyPath: [...keyPath, group.key],
    };
  }, [groupDepth, isReadonly, keyPath, group.key]);

  return (
    <ArtifactContext.Provider value={contextValue}>
      <Box vStack className="w-full">
        {label && <P className={textClass}>{label}</P>}
        {blocks.map((b) => {
          return <BlockRenderer key={b.key} block={b} />;
        })}
      </Box>
    </ArtifactContext.Provider>
  );
};

const BlockRenderer: React.FC<{
  block: Block;
}> = ({ block }) => {
  return match(block)
    .with({ type: 'text' }, (text) => (
      <TextFieldBlockRenderer textField={text} />
    ))
    .with({ type: 'checkbox' }, (checkbox) => (
      <CheckboxBlockRenderer checkbox={checkbox} />
    ))
    .with({ type: 'group' }, (group) => <GroupRenderer group={group} />)
    .with({ type: 'range' }, (range) => <RangeRenderer range={range} />)
    .exhaustive();
};

export interface DynamicArtifactRendererProps {
  isReadonly: boolean;
  blocks: Block[];
}

export const DynamicArtifactRenderer: React.FC<
  DynamicArtifactRendererProps & Partial<DynamicArtifactSaveContextProps>
> = ({
  blocks,
  isReadonly,
  saveBlock = defaultArtifactSaveContextProps.saveBlock,
  onPendingChanges = defaultArtifactSaveContextProps.onPendingChanges,
}) => {
  const contextValue = useMemo(
    () => ({ isReadonly, groupDepth: 0, keyPath: [] }),
    [isReadonly],
  );

  const saveContextValue: DynamicArtifactSaveContextProps = useMemo(
    () => ({
      saveBlock,
      onPendingChanges,
    }),
    [saveBlock, onPendingChanges],
  );

  return (
    <DynamicArtifactSaveContext.Provider value={saveContextValue}>
      <ArtifactContext.Provider value={contextValue}>
        <Box vStack className="w-full gap-4">
          {blocks.map((b) => (
            <BlockRenderer key={b.key} block={b} />
          ))}
        </Box>
      </ArtifactContext.Provider>
    </DynamicArtifactSaveContext.Provider>
  );
};
