import * as he from 'he';

const removeSpecialCharacters = (str = '') =>
  str
    .trim()
    .replaceAll('\n\n-', '')
    .replaceAll('\n-', '')
    .replaceAll('\n\n*', '')
    .replaceAll('\n*', '')
    .replaceAll('\n', '');

export const areStringsEqualWithoutSpecialCharacters = (
  a: string | null = '',
  b: string | null = '',
) => {
  const cleanA = he.decode(removeSpecialCharacters(a ?? ''));
  const cleanB = he.decode(removeSpecialCharacters(b ?? ''));
  return cleanA === cleanB;
};
