import { Slot } from '@radix-ui/react-slot';
import React, { HTMLProps } from 'react';

import { tv } from './cn';

const layoutVariants = tv({
  slots: {
    base: 'bg-gray-3 relative flex h-full flex-col overflow-auto',
    main: 'w-full flex-1 pb-4',
    header: [
      'fixed top-0 z-40 h-16 w-full',
      'shadow-gray-5/60 bg-white/80 shadow-md backdrop-blur-md',
    ],
    container: 'container h-full',
    headerSpacer: 'min-h-16',
    footer:
      'bg-gray-2 border-gray-6 shadow-gray-5/40 sticky bottom-0 z-40 mt-4 h-16 w-full border-t shadow-md',
  },
  variants: {
    size: {
      page: {
        base: 'h-dvh min-h-dvh',
      },
      container: {
        base: 'h-full',
      },
      fillScreen: {
        main: 'flex h-full overflow-auto',
        container: 'flex-1 overflow-auto',
      },
    },
  },
  defaultVariants: {
    size: 'container',
  },
});

const { base, footer, container, header, headerSpacer, main } =
  layoutVariants();

interface LayoutProps extends HTMLProps<HTMLDivElement> {
  asChild?: boolean;
}

export const PageLayout = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <Comp
        className={base({ size: 'page', className })}
        ref={ref}
        {...props}
      />
    );
  },
);

export const Layout = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <Comp
        className={base({ size: 'container', className })}
        ref={ref}
        {...props}
      />
    );
  },
);

interface HeaderProps extends HTMLProps<HTMLDivElement> {
  asChild?: boolean;
}

export const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <>
        <header className={header()} ref={ref} {...props}>
          <Comp
            className={container({
              className: [
                'flex flex-1 items-center gap-x-4 self-stretch lg:gap-x-6',
                className,
              ],
            })}
          >
            {props.children}
          </Comp>
        </header>
        <div className={headerSpacer()} />
      </>
    );
  },
);

interface MainProps extends HTMLProps<HTMLDivElement> {
  asChild?: boolean;
  fillScreen?: boolean;
}

export const Main = React.forwardRef<HTMLDivElement, MainProps>(
  ({ className, asChild = false, fillScreen, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <main
        className={main({ size: fillScreen ? 'fillScreen' : undefined })}
        ref={ref}
        {...props}
      >
        <Comp className={container({ className: [className] })}>
          {props.children}
        </Comp>
      </main>
    );
  },
);

interface FooterProps extends HTMLProps<HTMLDivElement> {
  asChild?: boolean;
}

export const Footer = React.forwardRef<HTMLDivElement, FooterProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <footer className={footer()} ref={ref} {...props}>
        <Comp
          className={container({
            className: [
              'flex flex-1 items-center gap-x-4 p-2 lg:gap-x-6 lg:p-3',
              className,
            ],
          })}
        >
          {props.children}
        </Comp>
      </footer>
    );
  },
);
