import React from 'react';
import { match } from 'ts-pattern';

import { HeadingTextStylesProps, textStyles } from '@eluve/components';

import { ContentEditable } from './ContentEditable';

type Size = 1 | 2 | 3 | 4 | 5;

type HeaderElement = `h${Size}`;

export interface EditableTitleProps {
  title: string;
  label?: string;
  onTitleChange: (title: string) => void;
  element?: HeaderElement;
  isReadonly?: boolean;
}

export const EditableTitle: React.FC<
  EditableTitleProps & HeadingTextStylesProps
> = ({
  title,
  label = 'Edit title',
  onTitleChange,
  isReadonly = false,
  size = 'xs',
  element: Component = 'h2',
}) => {
  const NameHeading = (
    <Component
      className={textStyles.heading({ size, className: 'inline text-wrap' })}
    >
      {title}
    </Component>
  );

  return match(isReadonly)
    .with(true, () => NameHeading)
    .otherwise(() => (
      <ContentEditable editButtonLabel={label} onContentChange={onTitleChange}>
        {NameHeading}
      </ContentEditable>
    ));
};
