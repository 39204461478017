import { match } from 'ts-pattern';

import { textStyles } from '@eluve/components';

/** Calculates suitable CSS classes to be used for rendering text that should get smaller based on the depth  */
export const getDepthTextClass = (depth: number) =>
  match(depth)
    .with(1, 2, 3, (d) =>
      textStyles.title({
        weight: 'semibold',
        size: match(d)
          .with(1, () => 'l' as const)
          .with(2, () => 'm' as const)
          .otherwise(() => 's' as const),
      }),
    )
    .with(4, 5, 6, (d) =>
      textStyles.body({
        weight: 'semibold',
        size: match(d)
          .with(4, () => 'l' as const)
          .with(5, () => 'm' as const)
          .otherwise(() => 's' as const),
      }),
    )
    .otherwise(() => textStyles.label({ size: 'l', weight: 'semibold' }));
