import React, { Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { BackBreadcrumbItem, ShowAllAppointmentsToggle } from '@eluve/blocks';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  SidebarTrigger,
  TableSkeleton,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { formatHumanName } from '@eluve/utils';

import { PatientAppointmentsList } from './PatientAppointmentsList';

const basicPatientDetailsFragment = graphql(`
  fragment basicDetails on Patients {
    __typename
    id
    firstName
    lastName
  }
`);

export const PatientDetailsPage: React.FC = () => {
  const { tenantId, patientId } = useParams() as {
    tenantId: string;
    patientId: string;
  };

  const basicDetails = useCompleteFragment({
    fragment: basicPatientDetailsFragment,
    key: {
      id: patientId,
    },
    strict: false,
  });

  return (
    <ReskinMain>
      <ReskinHeader>
        <SidebarTrigger />
        <Breadcrumb className="flex-1">
          <BreadcrumbList>
            <BackBreadcrumbItem />
            <BreadcrumbItem>
              <Link to={`/tenants/${tenantId}/patients`}>Patients</Link>
            </BreadcrumbItem>

            <BreadcrumbSeparator>/</BreadcrumbSeparator>
            <BreadcrumbItem className="text-gray-12 privacy-text">
              {basicDetails?.firstName
                ? formatHumanName(basicDetails.firstName, basicDetails.lastName)
                : 'Loading...'}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </ReskinHeader>
      <ReskinContent variant="fullWidth">
        <Box vStack className="items-stretch gap-4">
          <ShowAllAppointmentsToggle />
          <Suspense fallback={<TableSkeleton />}>
            <PatientAppointmentsList patientId={patientId} />
          </Suspense>
        </Box>
      </ReskinContent>
    </ReskinMain>
  );
};
