import { MicIcon } from 'lucide-react';
import React from 'react';

import { AlertDescription, HStack } from '@eluve/components';

export const TranscriptionWarning: React.FC = () => {
  return (
    <HStack className="pt-5">
      <MicIcon className="h-8 w-8" />
      <div>
        <AlertDescription className="font-semibold">
          Live transcription has been paused.
        </AlertDescription>
        <AlertDescription>
          You are still in session. We will transcribe the full conversation
          when the appointment is over.
        </AlertDescription>
      </div>
    </HStack>
  );
};
