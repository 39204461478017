import { CheckIcon, ContactRoundIcon, XIcon } from 'lucide-react';
import { useMemo } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { tv } from '@eluve/components';
import {
  Avatar,
  AvatarFallback,
  Box,
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
} from '@eluve/components';
import { useAppointmentPatient } from '@eluve/frontend-appointment-hooks';

import { NewPatientOption } from './NewPatientOption';
import { PatientOption } from './PatientOption';
import { tenantPatientsFragment } from './operations';
import { PatientOptionInfo } from './utils';

interface PatientSelectorProps {
  disabled?: boolean;
  tenantId: string;
  onPatientSelected?: (patient: PatientOptionInfo | null) => void;
  onOptimisticUpdate?: (patientId: string) => void;
}

const selectPatientVariant = tv({
  base: 'hover:bg-gray-3 w-full px-4',
  variants: {
    disabled: {
      true: 'pointer-events-none',
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

export const PatientSelector: React.FC<PatientSelectorProps> = ({
  tenantId,
  onPatientSelected,
  onOptimisticUpdate,
  disabled = false,
}) => {
  const data = useCompleteFragment({
    fragment: tenantPatientsFragment,
    key: {
      id: tenantId,
    },
    strict: false,
  });

  const patients = useMemo(() => data?.patients ?? [], [data]);

  const selectedPatient = useAppointmentPatient();

  const handlePatientSelected = (patient?: PatientOptionInfo) => {
    onPatientSelected?.(patient ?? null);
  };

  return (
    <Combobox disabled={disabled}>
      <ComboboxSelectButton className={selectPatientVariant({ disabled })}>
        {selectedPatient ? (
          <PatientOption {...selectedPatient} />
        ) : (
          <Box hStack>
            <Avatar>
              <AvatarFallback>
                <ContactRoundIcon size={16} />
              </AvatarFallback>
            </Avatar>
            <span className="text-gray-11">Select Patient</span>
          </Box>
        )}
      </ComboboxSelectButton>
      <ComboboxDropdown
        className="pointer-events-auto"
        searchPlaceholder="Search or Add patients"
      >
        {selectedPatient?.id && (
          <button
            className="hover:bg-gray-3 mb-2 w-full rounded-md px-4 py-2 text-left"
            onClick={() => handlePatientSelected(undefined)}
          >
            <Box hStack className="text-gray-10">
              <XIcon size={24} className="m-1" />
              <span>Clear Selected Patient</span>
            </Box>
          </button>
        )}
        {patients.map((patient) => {
          const searchValue = [
            patient.firstName,
            patient.lastName,
            patient.id,
            patient.external_patients_info.map(
              (info) => info.externalPatientId,
            ),
          ].join(' ');

          return (
            <ComboboxOption
              key={patient.id}
              searchValue={searchValue}
              onSelect={() => handlePatientSelected(patient)}
            >
              <Box hStack fullWidth>
                <Box className="flex-1">
                  <PatientOption {...patient} />
                </Box>
                {selectedPatient?.id === patient.id && (
                  <CheckIcon className="text-brand-9 w-max" />
                )}
              </Box>
            </ComboboxOption>
          );
        })}
        <NewPatientOption
          tenantId={tenantId}
          onCreateNewPatient={handlePatientSelected}
          onOptimisticUpdate={onOptimisticUpdate}
        />
      </ComboboxDropdown>
    </Combobox>
  );
};
