import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { toast } from 'sonner';

import { cacheUtils } from '@eluve/apollo-client';
import { useTypingThrottle } from '@eluve/blocks';
import { Box, Button } from '@eluve/components';
import {
  latestHumanSOAPNoteFragment,
  useAppointmentId,
  useTenantId,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { AppointmentSummaryKeys } from '@eluve/utils';

import { pendingUnsavedChangesStore } from './components/AppointmentLastSaved';

const updateHumanSoapNoteMutation = graphql(`
  mutation insertHumanSummary(
    $tenantId: uuid!
    $humanOutputId: uuid!
    $summary: jsonb!
  ) {
    updateHumanOutputsByPk(
      pkColumns: { tenantId: $tenantId, id: $humanOutputId }
      _set: { content: $summary }
    ) {
      __typename
      id
      content
      updatedAt
    }
  }
`);

export const useEditSummary = () => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantId();
  const [updateHumanSoapNote] = useMutation(updateHumanSoapNoteMutation);

  const editSummary = useCallback(
    async (key: AppointmentSummaryKeys, content: string) => {
      const note = cacheUtils.readFragment({
        fragment: latestHumanSOAPNoteFragment,
        key: { id: appointmentId },
        returnPartialData: true,
      });

      const humanOutputId = note?.humanOutputs?.[0]?.output?.id ?? '';

      const summaryContent = {
        ...note?.humanOutputs?.[0]?.output?.content,
      };

      if (summaryContent[key] === content) {
        return;
      }

      summaryContent[key] = content;

      await updateHumanSoapNote({
        variables: {
          tenantId,
          humanOutputId,
          summary: summaryContent,
        },
        onError: () => {
          toast.error(
            <Box spaceBetween fullWidth className="items-center">
              <span>Failed to save summary</span>
              <Button
                onClick={() => editSummary(key, content)}
                variant="destructive"
                className="float-end"
              >
                Retry
              </Button>
            </Box>,
            {
              id: 'edit-summary-error',
            },
          );
        },
      });

      pendingUnsavedChangesStore.setState(false);
    },
    [appointmentId, tenantId, updateHumanSoapNote],
  );

  const editSummaryThrottled = useTypingThrottle(editSummary);

  return editSummaryThrottled;
};
