import { useMutation } from '@apollo/client';
import { PencilIcon } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

interface RenameTenantActionProps {
  tenantId: string;
}

const tenantNameFragment = graphql(`
  fragment TenantName on Tenants {
    name
  }
`);

const renameTenantMutation = graphql(`
  mutation renameTenant($tenantId: uuid!, $name: String!) {
    updateTenantsByPk(pkColumns: { id: $tenantId }, _set: { name: $name }) {
      id
      name
    }
  }
`);

export const RenameTenantAction: React.FC<RenameTenantActionProps> = ({
  tenantId,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { name: defaultTenantName } = useCompleteFragment({
    fragment: tenantNameFragment,
    key: { id: tenantId },
  });
  const [renameTenant] = useMutation(renameTenantMutation, {
    optimisticResponse: ({ name, tenantId }) => ({
      updateTenantsByPk: {
        id: tenantId,
        name,
        __typename: 'Tenants',
      },
    }),
    onCompleted: () => {
      toast.success('Tenant renamed');
    },
    onError: () => {
      toast.error('Failed to rename tenant');
      setIsDialogOpen(true);
    },
  });

  const [newTenantName, setNewTenantName] = useState(defaultTenantName);

  const handleDialogOpen = (open: boolean) => {
    if (open) {
      setNewTenantName(defaultTenantName);
      setIsDialogOpen(open);
      return;
    }

    if (
      newTenantName !== defaultTenantName &&
      !window.confirm('Are you sure you want to discard changes?')
    ) {
      return;
    }

    setIsDialogOpen(false);
  };

  const handleRenameTenant = () => {
    renameTenant({
      variables: {
        tenantId,
        name: newTenantName,
      },
    });

    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={handleDialogOpen} modal>
      <DialogTrigger asChild>
        <Button variant="outline" size="icon">
          <TooltipLabel label="Rename">
            <PencilIcon />
          </TooltipLabel>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Rename Tenant</DialogTitle>
        </DialogHeader>
        <Input
          placeholder="Enter new tenant name"
          value={newTenantName}
          onChange={(e) => setNewTenantName(e.target.value)}
        />
        <DialogFooter>
          <Button variant="outline" onClick={() => handleDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleRenameTenant}>Rename</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
