import copy from 'copy-to-clipboard';
import { CopyIcon } from 'lucide-react';
import { useState } from 'react';

import {
  Button,
  ButtonProps,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@eluve/components';

interface CopyRichTextButtonProps {
  getContentElement: () => HTMLElement | null;
  hideContentInTooltip?: boolean;
}

export const CopyRichTextButton: React.FC<
  ButtonProps & CopyRichTextButtonProps
> = ({
  getContentElement,
  hideContentInTooltip = false,
  children,
  ...buttonProps
}) => {
  const [tooltipContent, setTooltipContent] = useState('Copy to clipboard');
  const [open, setOpen] = useState(false);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const element = getContentElement();
    if (!element) return;

    const clonedElement = element.cloneNode(true) as HTMLElement;
    clonedElement.querySelectorAll('button').forEach((a) => {
      a.remove();
    });

    const outerHTML = clonedElement.innerHTML;
    const plainText = clonedElement.textContent ?? '';

    if (typeof ClipboardItem !== 'undefined') {
      const html = new Blob([outerHTML], { type: 'text/html' });
      const text = new Blob([plainText], { type: 'text/plain' });
      const data = new ClipboardItem({ 'text/html': html, 'text/plain': text });
      await navigator.clipboard.write([data]);
    } else {
      copy(outerHTML, { format: 'text/html' });
      copy(plainText);
    }

    setTooltipContent('Copied!');
    setOpen(true);
    setTimeout(() => setTooltipContent('Copy to clipboard'), 3000);
    e.currentTarget?.blur();
  };

  return (
    <TooltipProvider>
      <Tooltip
        delayDuration={0}
        open={open}
        onOpenChange={setOpen}
        disableHoverableContent={hideContentInTooltip}
      >
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            onClick={handleClick}
            {...buttonProps}
          >
            <CopyIcon className="h-4 w-4" />
            {children}
          </Button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent sideOffset={8}>{tooltipContent}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};
