import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';

import { Box, FCC } from '@eluve/components';
import { useAppointmentActivity } from '@eluve/frontend-appointment-hooks';

import {
  SUMMARY_TAB,
  TRANSCRIPT_TAB,
  useAppointmentTabNav,
} from '../useAppointmentTabNav';

const Tab: FCC<{
  onClick: () => void;
  active: boolean;
  disabled?: boolean;
}> = ({ onClick, active, children, disabled }) => (
  <Box zStack className="flex-1">
    {active && (
      <motion.div
        className="bg-gray-1 absolute inset-0 z-0 rounded-lg"
        layout="position"
        layoutId="active-tab"
      />
    )}
    <button onClick={onClick} className="z-10 p-2" disabled={disabled}>
      {children}
    </button>
  </Box>
);

interface AppointmentTabsProps {
  disabledSummary?: boolean;
}

export const AppointmentTabs: React.FC<AppointmentTabsProps> = ({
  disabledSummary,
}) => {
  const { setTab, tab } = useAppointmentTabNav();
  const { isSummarizationInProgress, isTranscriptionInProgress } =
    useAppointmentActivity();

  return (
    <AnimatePresence>
      <LayoutGroup>
        <Box
          hStack
          className="bg-gray-3 flex w-full overflow-hidden rounded-lg p-1"
        >
          <Tab
            onClick={() => setTab(TRANSCRIPT_TAB)}
            active={tab === TRANSCRIPT_TAB}
          >
            <Box hStack className="justify-center">
              {isTranscriptionInProgress && (
                <Loader2 className="text-brand-8 h-5 animate-spin" />
              )}
              Transcript
            </Box>
          </Tab>
          <Tab
            onClick={() => setTab(SUMMARY_TAB)}
            active={tab === SUMMARY_TAB}
            disabled={disabledSummary}
          >
            <Box hStack className="justify-center">
              {isSummarizationInProgress && (
                <Loader2 className="text-brand-8 h-5 animate-spin" />
              )}
              Summary
            </Box>
          </Tab>
        </Box>
      </LayoutGroup>
    </AnimatePresence>
  );
};

export const TranscriptTabContent: FCC = ({ children }) => {
  const { tab } = useAppointmentTabNav();
  if (tab !== TRANSCRIPT_TAB) return null;
  return (
    <motion.div
      initial={{ x: -60, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 60, opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="flex h-full w-full flex-1 flex-col gap-2"
    >
      {children}
    </motion.div>
  );
};

export const SummaryTabContent: FCC = ({ children }) => {
  const { tab } = useAppointmentTabNav();
  if (tab !== SUMMARY_TAB) return null;
  return (
    <motion.div
      initial={{ x: 60, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -60, opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="flex h-full w-full flex-1 flex-col gap-2 py-2"
    >
      {children}
    </motion.div>
  );
};
