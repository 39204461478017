import { fromCallback } from 'xstate';

import { usePreventAutoReloadOnNewVersion } from '@eluve/blocks';

export const preventBrowserClose = fromCallback(() => {
  usePreventAutoReloadOnNewVersion.setState(true);

  const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
  };

  window.addEventListener('beforeunload', beforeUnloadHandler);
  return () => {
    usePreventAutoReloadOnNewVersion.setState(false);
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  };
});
