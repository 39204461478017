import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
import { createTV, defaultConfig } from 'tailwind-variants';

type MergeConfig = typeof defaultConfig.twMergeConfig;

const twMergeConfig: MergeConfig = {
  override: {
    conflictingClassGroupModifiers: { 'font-size': [] },
    conflictingClassGroups: { 'font-size': [] },
  },
  extend: {
    classGroups: {},
  },
};

defaultConfig.twMergeConfig = twMergeConfig;

export const tv = createTV({
  twMergeConfig,
});

const twMerge = extendTailwindMerge(twMergeConfig);

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};
