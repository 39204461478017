import { useMutation } from '@apollo/client';
import { InfoIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { toast as sonnerToast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { useCompleteFragment } from '@eluve/apollo-client';
import {
  LanguageSelectorComboBox,
  TooltipLabel,
  useDialog,
} from '@eluve/blocks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  HStack,
  P,
  VStack,
  textStyles,
  useToast,
} from '@eluve/components';
import {
  appointmentLanguagesFragment,
  useAppointmentId,
} from '@eluve/frontend-appointment-hooks';
import { useTenantUserSettings } from '@eluve/frontend-feature-user-settings';
import { graphql } from '@eluve/graphql.tada';
import {
  DEFAULT_LANGUAGE,
  SupportedLanguagesByCode,
} from '@eluve/language-utils';
import { useNamedLogger } from '@eluve/logger';
import { useTenantIdFromParams } from '@eluve/session-helpers';

const setAppointmentLanguageMutation = graphql(
  `
    mutation setAppointmentLanguage(
      $tenantId: uuid!
      $appointmentId: uuid!
      $inputLanguage: String!
      $outputLanguage: String!
    ) {
      updateAppointmentsByPk(
        pkColumns: { tenantId: $tenantId, id: $appointmentId }
        _set: { inputLanguage: $inputLanguage, outputLanguage: $outputLanguage }
      ) {
        __typename
        id
        inputLanguage
        outputLanguage
      }
    }
  `,
  [],
);

type AppointmentLanguageSelectorProps = {
  inputLanguage?: boolean;
  outputLanguage?: boolean;
  regenerateSummary?: boolean;
  onInputLanguageChange?: (newCode: string) => void;
};

/**
 * Allows the user to update the language of the appointment.
 *
 * @param inputLanguage determines if the input language selector should be displayed
 * @param outputLanguage determines if the output language selector should be displayed
 * @param regenerateSummary determines if the summary should be regenerated after the output language is changed
 * @param onInputLanguageChange callback function to be called when the input language is changed
 */
export const AppointmentLanguageSelector: React.FC<
  AppointmentLanguageSelectorProps
> = ({
  inputLanguage = false,
  outputLanguage = false,
  regenerateSummary = false,
  onInputLanguageChange,
}) => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantIdFromParams() ?? '';
  const { settings: userDefaultSettings } = useTenantUserSettings();
  const logger = useNamedLogger(AppointmentLanguageSelector.name);
  const { isDialogOpen, toggleDialog, setIsDialogOpen } = useDialog();
  const apiClient = useApiClient();
  const { toast } = useToast();
  const [selectedOutputLanguage, setSelectedOutputLanguage] = useState<
    string | null
  >(null);

  const appointment = useCompleteFragment({
    fragment: appointmentLanguagesFragment,
    key: {
      id: appointmentId,
    },
  });

  const inputLanguageCode =
    appointment?.inputLanguage ??
    userDefaultSettings?.inputLanguage ??
    DEFAULT_LANGUAGE;
  const outputLanguageCode =
    appointment?.outputLanguage ??
    userDefaultSettings?.outputLanguage ??
    DEFAULT_LANGUAGE;

  const [setAppointmentLanguage] = useMutation(setAppointmentLanguageMutation, {
    optimisticResponse: (input) => ({
      updateAppointmentsByPk: {
        __typename: 'Appointments' as const,
        id: appointmentId,
        inputLanguage: input?.inputLanguage ?? inputLanguageCode,
        outputLanguage: input?.outputLanguage ?? outputLanguageCode,
      },
    }),
  });

  // On load, select the defaults
  useEffect(() => {
    if (!appointment?.inputLanguage || !appointment?.outputLanguage) {
      setAppointmentLanguage({
        variables: {
          tenantId,
          appointmentId,
          inputLanguage: inputLanguageCode,
          outputLanguage: outputLanguageCode,
        },
      });
    }
  }, [
    appointment,
    appointmentId,
    tenantId,
    inputLanguageCode,
    outputLanguageCode,
    setAppointmentLanguage,
  ]);

  const preferredLanguages = [];
  if (userDefaultSettings?.inputLanguage) {
    preferredLanguages.push(userDefaultSettings.inputLanguage);
  }
  if (userDefaultSettings?.outputLanguage) {
    preferredLanguages.push(userDefaultSettings.outputLanguage);
  }

  const handleOutputLanguageChangeAndRegenerate = async () => {
    if (!selectedOutputLanguage) {
      logger.error(
        'Output language update dialog open but no selected language',
        {
          tenantId,
          appointmentId,
        },
      );
      sonnerToast.error('Something went wrong. Please try again.');
      return;
    }

    // update output language
    const updateLanguageRes = await setAppointmentLanguage({
      variables: {
        tenantId,
        appointmentId,
        inputLanguage: inputLanguageCode,
        outputLanguage: selectedOutputLanguage,
      },
    });

    if (updateLanguageRes.errors) {
      sonnerToast.error('Something went wrong. Please try again.');
      logger.error('Failed to update language', {
        tenantId,
        appointmentId,
        selectedLanguage: selectedOutputLanguage,
      });

      // do not regenerate if we could not update the language
      return;
    }

    toggleDialog();

    // trigger re-generate
    try {
      const result = await apiClient.llm.summarizeAppointment({
        body: {},
        params: {
          tenantId: tenantId!,
          appointmentId,
        },
      });

      if (result.status !== 201) {
        sonnerToast.error('Failed to regenerate summary. Please try again.');
        logger.error('Failed to regenerate summary after language update', {
          tenantId,
          appointmentId,
          selectedLanguage: selectedOutputLanguage,
        });
        setSelectedOutputLanguage(null);
        return;
      }

      toast({
        variant: 'success',
        title: 'Regenerating your summary in the new language',
        description: 'Your new summary should be available soon',
        duration: 4 * 1000,
      });
    } catch (e) {
      sonnerToast.error('Failed to regenerate summary. Please try again.');
      logger.error(
        'Unexpected error when attempting to regenerate summary after language update',
        {
          tenantId,
          appointmentId,
          selectedLanguage: selectedOutputLanguage,
        },
      );
      setSelectedOutputLanguage(null);
    }
  };

  return (
    <>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="flex flex-col gap-5 p-8">
          <H2>Changing the notes language will generate a new note</H2>
          <VStack>
            <P>
              The new note will be generated in{' '}
              {SupportedLanguagesByCode[selectedOutputLanguage!]}.
            </P>
            <P>This will overwrite the current summary.</P>
          </VStack>
          <DialogFooter className="w-full flex-row justify-end gap-2">
            <Button
              variant="ghost"
              className="bg-gray-3 hover:bg-gray-4 w-fit"
              onClick={toggleDialog}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOutputLanguageChangeAndRegenerate}
              className="w-fit"
            >
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <VStack gap={1} className="items-start md:flex-row">
        {inputLanguage && (
          <HStack>
            {(appointment?.status === 'ACTIVE' ||
              appointment?.status === 'COMPLETED') && (
              <TooltipLabel
                label="Changing the spoken language will re-transcribe the entire conversation from the session's start."
                showArrow={true}
                tooltipArrowProps={{
                  className: 'fill-white',
                  height: 10,
                  width: 20,
                }}
                tooltipContentProps={{
                  align: 'start',
                  alignOffset: -50,
                  sideOffset: 8,
                  className:
                    'bg-white border-none text-gray-11 max-w-60 text-wrap shadow-all-around',
                }}
              >
                <InfoIcon size={16} />
              </TooltipLabel>
            )}
            <p
              className={textStyles.body({
                weight: 'semibold',
                className: 'text-gray-9 text-nowrap',
                size: 'm',
              })}
            >
              Spoken Language:
            </p>

            <LanguageSelectorComboBox
              selectButtonStyles={textStyles.body({
                weight: 'semibold',
                className: 'text-gray-9 border-none text-nowrap',
                size: 'm',
              })}
              selectedLanguageCode={inputLanguageCode}
              onSelectedLanguageCode={async (newCode) => {
                if (newCode === inputLanguageCode) {
                  return;
                }

                await setAppointmentLanguage({
                  variables: {
                    tenantId,
                    appointmentId,
                    inputLanguage: newCode,
                    outputLanguage: outputLanguageCode,
                  },
                });

                onInputLanguageChange?.(newCode);
              }}
              preferredLanguages={preferredLanguages}
              showLanguageIcon={false}
            />
          </HStack>
        )}

        {outputLanguage && (
          <HStack>
            <p
              className={textStyles.body({
                className: 'text-gray-9 text-nowrap',
                size: 'm',
                weight: 'semibold',
              })}
            >
              Notes Language:
            </p>
            <LanguageSelectorComboBox
              selectButtonStyles={textStyles.body({
                className: 'text-gray-9 border-none text-nowrap',
                size: 'm',
                weight: 'semibold',
              })}
              selectedLanguageCode={outputLanguageCode}
              onSelectedLanguageCode={async (newCode) => {
                if (newCode === outputLanguageCode) {
                  return;
                }
                if (regenerateSummary) {
                  toggleDialog();
                  setSelectedOutputLanguage(newCode);
                } else {
                  await setAppointmentLanguage({
                    variables: {
                      tenantId,
                      appointmentId,
                      inputLanguage: inputLanguageCode,
                      outputLanguage: newCode,
                    },
                  });
                }
              }}
              preferredLanguages={preferredLanguages}
              showLanguageIcon={false}
            />
          </HStack>
        )}
      </VStack>
    </>
  );
};
