import { Slot } from '@radix-ui/react-slot';
import { XIcon } from 'lucide-react';
import React, { useRef } from 'react';
import { HTMLProps } from 'react';
import { useMedia } from 'react-use';
import { match } from 'ts-pattern';

import { Box } from './box';
import { Button } from './button';
import { tv } from './cn';
import { createSidebarControl } from './createSidebarControl';
import { Sheet, SheetOverlay, SheetPortal } from './sheet';

export const detailsSidebarVariants = tv({
  base: 'bg-background h-full self-stretch overflow-auto border-l p-4',
  variants: {
    variant: {
      fixed: 'col-start-2 row-span-2 row-start-2 min-w-72',
      overlay: [
        'fixed inset-y-0 right-0 z-50 flex flex-col shadow-lg',
        'transition ease-in-out',
        'data-[state=open]:animate-in data-[state=closed]:animate-out',
        'data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
        'data-[state=closed]:duration-300 data-[state=open]:duration-500',
      ],
    },
  },
  defaultVariants: {
    variant: 'fixed',
  },
});

interface LayoutProps extends HTMLProps<HTMLDivElement> {
  asChild?: boolean;
}

const useDetailsSidebarVariant = (breakPointWidth: number) =>
  useMedia(`(min-width: ${breakPointWidth}px)`) ? 'fixed' : 'overlay';

interface DetailsSidebarProps extends LayoutProps {
  /**
   * Whether or not a default close button should be included as part of the rendered
   * content. If false, the consumer will need to provide their own close button
   */
  includeCloseTrigger?: boolean;

  blurBackground?: boolean;
  /**
   * The mode to operate in. Details mode shows content to the side of the main content if there
   * is enough space for it to fit. Drawer mode will always show the sidebar as an overlay
   */
  mode?: 'details' | 'drawer';
}

interface DetailsSidebarTriggerProps extends HTMLProps<HTMLButtonElement> {
  asChild?: boolean;
  /**
   * Indicates whether this trigger should always be shown, regardless of the screen size.
   * If true, it will always be rendered. If false, it will be shown only when below the breakpoint
   * that controls the content to be shown in a drawer
   */
  alwaysShown?: boolean;
}

export type SidebarArgs = {
  mode?: 'details' | 'drawer';
  breakpointWidth?: number;
};

export const createSidebar = (args: SidebarArgs | undefined = undefined) => {
  const { breakpointWidth = 1024, mode = 'details' } = args ?? {};
  const { useSidebar, useSidebarTrigger } = createSidebarControl();

  const DetailsSidebarTrigger = React.forwardRef<
    HTMLButtonElement,
    DetailsSidebarTriggerProps
  >(({ asChild = false, alwaysShown = false, children, ...props }, ref) => {
    const variant = useDetailsSidebarVariant(breakpointWidth);
    const { isVisible, setSidebarOpen } = useSidebarTrigger();

    const showButton = alwaysShown || (variant === 'overlay' && isVisible);

    if (!showButton) {
      return null;
    }

    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        {...props}
        ref={ref}
        type="button"
        onClick={() => {
          setSidebarOpen((isOpen) => !isOpen);
        }}
      >
        {children}
      </Comp>
    );
  });

  const DetailsSidebar = React.forwardRef<HTMLDivElement, DetailsSidebarProps>(
    (
      {
        className,
        asChild = false,
        includeCloseTrigger = true,
        blurBackground = true,
        children,
        ...props
      },
      ref,
    ) => {
      const variant = useDetailsSidebarVariant(breakpointWidth);
      const finalVariant = mode === 'drawer' ? 'overlay' : variant;
      const overlayRef = useRef<HTMLDivElement>(null);

      const Comp = asChild ? Slot : 'aside';
      const { isOpen, setSidebarOpen } = useSidebar();

      const sidebarContent = (
        <Comp
          className={detailsSidebarVariants({
            className,
            variant: finalVariant,
          })}
          ref={ref}
          data-state={isOpen ? 'open' : 'closed'}
          {...props}
        >
          {includeCloseTrigger && (
            <Box className="flex w-full flex-col">
              <DetailsSidebarTrigger asChild alwaysShown={mode === 'drawer'}>
                <Button size="icon" variant="ghost" className="self-end">
                  <XIcon />
                </Button>
              </DetailsSidebarTrigger>
            </Box>
          )}
          {children}
        </Comp>
      );

      if (finalVariant === 'fixed') {
        return sidebarContent;
      }

      return (
        <Sheet open={isOpen} onOpenChange={setSidebarOpen}>
          <SheetPortal className="p-0">
            {match(blurBackground)
              .with(true, () => (
                <SheetOverlay
                  ref={overlayRef}
                  className="z-10"
                  onClick={(e) => {
                    // Only toggle sidebar open if its the actual overlay being clicked on, not events
                    // bubbling up from children
                    if (e.target === overlayRef.current) {
                      setSidebarOpen(false);
                    }
                  }}
                >
                  {sidebarContent}
                </SheetOverlay>
              ))
              .otherwise(() => sidebarContent)}
          </SheetPortal>
        </Sheet>
      );
    },
  );

  return { DetailsSidebar, DetailsSidebarTrigger };
};

export const { DetailsSidebar, DetailsSidebarTrigger } = createSidebar();
