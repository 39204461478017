import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import {
  useAppointmentActivity,
  useAppointmentStatus,
} from '@eluve/frontend-appointment-hooks';

import { SUMMARY_TAB, useAppointmentTabNav } from './useAppointmentTabNav';

export const useListenForAppointmentEnd = () => {
  const { isSummarizationInProgress } = useAppointmentActivity();
  const { setTab } = useAppointmentTabNav();
  const appointmentStatus = useAppointmentStatus();
  const prevAppointmentStatus = usePrevious(appointmentStatus);

  const [hasAppointmentTransitioned, setHasAppointmentTransitioned] =
    useState(false);

  // Detect the transition from ACTIVE to COMPLETED
  useEffect(() => {
    if (
      prevAppointmentStatus === 'ACTIVE' &&
      appointmentStatus === 'COMPLETED'
    ) {
      setHasAppointmentTransitioned(true);
    }
  }, [appointmentStatus, prevAppointmentStatus]);

  // Summarization starts a few seconds after the appt transitions to COMPLETED
  // so we need to wait for that before we can switch to the summary tab
  useEffect(() => {
    if (hasAppointmentTransitioned && isSummarizationInProgress) {
      setTab(SUMMARY_TAB);
      setHasAppointmentTransitioned(false);
    }
  }, [hasAppointmentTransitioned, isSummarizationInProgress, setTab]);
};
