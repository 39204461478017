import format from 'date-fns/format';

import { useCompleteFragment } from '@eluve/apollo-client';
import { useTenantId } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

const patientFragment = graphql(`
  fragment patient on Patients @_unmask {
    __typename
    id
    firstName
    lastName
    dateOfBirth
    external_patients_info {
      __typename
      id
      externalPatientId
      homePhoneNumber
      cellPhoneNumber
      workPhoneNumber
      email
      external_ehr {
        __typename
        id
        vendor
        domain
      }
    }
  }
`);

const patientsFragment = graphql(
  `
    fragment TenantPatients on Tenants @_unmask {
      patients {
        __typename
        ...patient
      }
    }
  `,
  [patientFragment],
);

export type Patient = {
  firstName: string;
  id: string;
  externalEhrId?: string | null;
  lastName?: string | null;
  dob?: string | null;
  phoneNumber?: string | null;
};

export const usePatients = () => {
  const tenantId = useTenantId();

  const data = useCompleteFragment({
    fragment: patientsFragment,
    key: {
      id: tenantId,
    },
    strict: false,
  });

  const patients = (data?.patients ?? []).map<Patient>((patient) => {
    const externalPatientInfo = patient.external_patients_info[0];
    const dateOfBirth = patient.dateOfBirth;
    const dob = dateOfBirth ? format(new Date(dateOfBirth), 'MM/dd/yyyy') : '';
    const phoneNumber =
      externalPatientInfo?.homePhoneNumber ??
      externalPatientInfo?.cellPhoneNumber ??
      externalPatientInfo?.workPhoneNumber;
    const externalEhrId = externalPatientInfo?.externalPatientId;

    return {
      firstName: patient.firstName,
      lastName: patient.lastName,
      id: patient.id,
      dob,
      externalEhrId,
      phoneNumber,
    };
  });

  return patients;
};
