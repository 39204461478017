import { textStyles } from '@eluve/components';
import { formatTo12HourTime } from '@eluve/date-utils';
import { useAppointmentStartedAt } from '@eluve/frontend-appointment-hooks';

export const SessionStartedAtTimestamp: React.FC = () => {
  const startedAt = useAppointmentStartedAt();
  return (
    <div>
      {startedAt && (
        <p
          className={textStyles.body({
            className: 'text-gray-9 text-nowrap',
            size: 'm',
            weight: 'semibold',
          })}
        >
          Session started at {formatTo12HourTime(startedAt)}
        </p>
      )}
    </div>
  );
};
