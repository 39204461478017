import { useMutation } from '@apollo/client';
import { TextIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import { v4 as uuid } from 'uuid';

import { Box, Button, Label, Textarea } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { useAppointmentTasksSelector } from '@eluve/user-local-files';

interface AddToTranscriptProps {
  appointmentId: string;
}

const insertTranscriptMutation = graphql(`
  mutation insertTranscript(
    $tenantId: uuid!
    $appointmentId: uuid!
    $transcript: String!
    $segmentId: uuid!
  ) {
    insertAppointmentSegmentsOne(
      object: {
        appointmentId: $appointmentId
        id: $segmentId
        recordingStartedAt: NOW
      }
    ) {
      id
    }

    insertTranscriptsOne(
      object: {
        appointmentId: $appointmentId
        transcript: $transcript
        transcriptionCompletedAt: NOW
        transcriptionStatus: COMPLETED
        segmentId: $segmentId
        transcriptType: BATCH
      }
    ) {
      id
      transcriptionStatus
    }

    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $appointmentId }
      _set: { status: ACTIVE }
    ) {
      __typename
      id
    }
  }
`);

export const AddToTranscript: React.FC<AddToTranscriptProps> = ({
  appointmentId,
}) => {
  const logger = useNamedLogger('AddToTranscript');
  const [insertTranscript, { loading }] = useMutation(insertTranscriptMutation);
  const [transcript, setTranscript] = useState('');
  const tenantId = useTenantIdFromParams();
  const hasPendingAppointmentTask = useAppointmentTasksSelector(({ context }) =>
    context.tasks.some((t) => t.appointmentId === appointmentId),
  );

  const disabled = useMemo(
    () => !transcript.trim() || loading,
    [transcript, loading],
  );

  const addTranscriptToAppointment = async () => {
    if (!transcript) {
      return;
    }

    const segmentId = uuid();

    const { errors } = await insertTranscript({
      context: {
        headers: {
          'x-hasura-role': 'eluve-admin',
        },
      },
      variables: {
        tenantId: tenantId ?? '',
        appointmentId: appointmentId,
        transcript: transcript,
        segmentId,
      },
    });

    if (errors) {
      logger.error('Failed to upload transcript');
      toast.error('Failed to upload transcript');
      return;
    }

    setTranscript('');
  };

  return (
    <Box vStack>
      <Label className="px-2">Transcript</Label>
      <Textarea
        className="box-shadow-none text-gray-12 h-[70px] w-full resize-none outline-none focus-visible:ring-0"
        value={transcript ?? ''}
        placeholder="Paste transcript here!"
        onChange={(e) => setTranscript(e.target.value)}
      />
      <Button
        type="button"
        className="w-fit"
        disabled={disabled || hasPendingAppointmentTask}
        onClick={addTranscriptToAppointment}
      >
        <TextIcon className="mr-2 h-4 w-4" />
        Add to Transcript
      </Button>
    </Box>
  );
};
