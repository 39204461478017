import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import { ColDefBuilder, DataTable, H3, P } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import {
  TenantPromptTemplateToggle,
  tenantPromptTemplateFragment,
} from '../../components/TenantPromptTemplateToggle';

const tenantPromptTemplatesFragment = graphql(
  `
    fragment TenantPromptTemplates on Tenants @_unmask {
      __typename
      id
      promptTemplates {
        ...TenantPromptTemplate
        prompt_template {
          __typename
          id
          name
          description
          updatedAt
        }
      }
    }
  `,
  [tenantPromptTemplateFragment],
);

const tenantPromptTemplatesQuery = graphql(
  `
    query getTenantPromptTemplates($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        ...TenantPromptTemplates
      }
    }
  `,
  [tenantPromptTemplatesFragment],
);

type TableRow = {
  tenantId: string;
  name: string;
  description?: string | null;
  isEnabled: boolean;
  promptTemplateId: string;
  templateUpdatedAt: string;
  accessUpdatedAt: string;
};

const colDefs = new ColDefBuilder<TableRow>()
  .detailsLink((r) => `/admin/prompts/${r.promptTemplateId}`)
  .defaultSortable('name')
  .defaultSortable('description')
  .defaultSortable('isEnabled', {
    label: 'Is Enabled',
    cellRenderer: (row) => {
      return (
        <TenantPromptTemplateToggle
          tenantId={row.tenantId}
          promptTemplateId={row.promptTemplateId}
          name={row.name}
        />
      );
    },
  })
  .dateSortable('templateUpdatedAt', 'Template Updated At')
  .dateSortable('accessUpdatedAt', 'Access Updated At')
  .build();

export const AdminTenantPromptTemplatesPage: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;

  const {
    data: { tenantsByPk: tenant },
  } = useSuspenseQuery(tenantPromptTemplatesQuery, {
    variables: {
      tenantId,
    },
  });

  const rows = useMemo(
    () =>
      (tenant?.promptTemplates ?? []).map<TableRow>((template) => ({
        tenantId,
        isEnabled: template.isEnabled,
        name: template.prompt_template.name,
        description: template.prompt_template.description,
        promptTemplateId: template.promptTemplateId,
        templateUpdatedAt: template.prompt_template.updatedAt,
        accessUpdatedAt: template.updatedAt,
      })),
    [tenant?.promptTemplates, tenantId],
  );

  return (
    <div className="grid gap-2">
      <div>
        <H3>Prompt Templates</H3>
        <P>Control which prompt templates this tenant will have access to</P>
      </div>
      <div className="w-full overflow-hidden">
        <DataTable
          data={rows}
          columns={colDefs}
          isPaginated={false}
          enableGlobalSearch
          isCompact
          initialSortingState={[
            {
              id: 'templateUpdatedAt',
              desc: true,
            },
          ]}
        />
      </div>
    </div>
  );
};
