import { MatchingRecord } from '@eluve/utils';

import { WorkerStatusTypesEnum } from './types';

export const WorkerStatusTypesLookup = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_STARTED: 'NOT_STARTED',
} as const satisfies MatchingRecord<WorkerStatusTypesEnum>;
