import Mark from 'mark.js';
import { useEffect, useRef } from 'react';

export const useTextHighlight = (
  text: string | string[],
  markOptions: Mark.MarkOptions = {},
  scrollToMatch = false,
) => {
  const containerRef = useRef<HTMLTableSectionElement>(null);
  const markingRef = useRef<Mark>();

  useEffect(() => {
    if (!containerRef.current) return;
    if (!markingRef.current) {
      markingRef.current = new Mark(containerRef.current);
    }
    markingRef.current.unmark({
      done: () => {
        markingRef.current?.mark(text, markOptions);
        if (scrollToMatch) {
          const firstMatch = containerRef.current?.querySelector('mark');
          firstMatch?.scrollIntoView({ behavior: 'smooth' });
        }
      },
    });
  }, [text, markOptions, scrollToMatch]);

  return containerRef;
};
