import { graphql } from '@eluve/graphql.tada';

export const medicalCodeFragment = graphql(`
  fragment MedicalCode on MedicalCodes @_unmask {
    __typename
    id
    code
    codeType
    description
  }
`);

export const appointmentBillingCodeRecommendationsFragment = graphql(
  `
    fragment BillingCodeRecommedations on Appointments @_unmask {
      __typename
      id
      billing_code_recommendations {
        __typename
        codeId
        appointmentId
        isFinal
        medical_code {
          ...MedicalCode
        }
      }
    }
  `,
  [medicalCodeFragment],
);

export const appointmentBillingCodesFragment = graphql(
  `
    fragment BillingCodes on Appointments @_unmask {
      __typename
      id
      billingCodes {
        __typename
        id
        medical_code {
          ...MedicalCode
        }
      }
    }
  `,
  [medicalCodeFragment],
);

export const acceptBillingCodeRecommendationMutation = graphql(`
  mutation acceptBillingCodeRecommendation(
    $appointmentId: uuid!
    $billingCodeId: uuid!
  ) {
    insertAppointmentBillingCodesOne(
      object: { appointmentId: $appointmentId, billingCodeId: $billingCodeId }
      onConflict: { constraint: uq_appointment_code, updateColumns: [] }
    ) {
      __typename
      id
    }
    updateAppointmentBillingCodeRecommendations(
      where: {
        appointmentId: { _eq: $appointmentId }
        codeId: { _eq: $billingCodeId }
      }
      _set: { isFinal: true }
    ) {
      returning {
        __typename
        appointmentId
        codeId
        isFinal
      }
    }
  }
`);

export const addAppointmentBillingCodeMutation = graphql(`
  mutation acceptBillingCodeRecommendation(
    $appointmentId: uuid!
    $billingCodeId: uuid!
  ) {
    insertAppointmentBillingCodesOne(
      object: { appointmentId: $appointmentId, billingCodeId: $billingCodeId }
      onConflict: { constraint: uq_appointment_code, updateColumns: [] }
    ) {
      __typename
      id
    }
  }
`);

export const removeBillingCodeMutation = graphql(`
  mutation removeBillingCode($appointmentId: uuid!, $billingCodeId: uuid!) {
    deleteAppointmentBillingCodes(
      where: {
        appointmentId: { _eq: $appointmentId }
        billingCodeId: { _eq: $billingCodeId }
      }
    ) {
      returning {
        __typename
        billingCodeId
      }
    }

    updateAppointmentBillingCodeRecommendations(
      where: {
        appointmentId: { _eq: $appointmentId }
        codeId: { _eq: $billingCodeId }
      }
      _set: { isFinal: false }
    ) {
      returning {
        __typename
        appointmentId
        codeId
        isFinal
      }
    }
  }
`);

export const getAppointmentBillingCodes = graphql(
  `
    query getAppointmentBillingCodes($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...BillingCodes
        ...BillingCodeRecommedations
      }
    }
  `,
  [
    appointmentBillingCodesFragment,
    appointmentBillingCodeRecommendationsFragment,
  ],
);
