import { useSuspenseQuery } from '@apollo/client';
import format from 'date-fns/format';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { H2, H3, Link } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import {
  AppointmentOutputsTabNav,
  outputTypesFragment,
} from './AppointmentOutputsTabNav';

const appointmentDetailsQuery = graphql(
  `
    query appointmentDetails($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        name
        startDate
        transcripts {
          transcript
        }
      }
      ...outputTypes
    }
  `,
  [outputTypesFragment],
);

export interface AppointmentDetailsPageProps {}

export const AppointmentDetailsPage: React.FC<
  AppointmentDetailsPageProps
> = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const {
    data: { appointmentsByPk: appointment },
  } = useSuspenseQuery(appointmentDetailsQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  if (!appointment) {
    throw new Error('Appointment not found');
  }

  return (
    <div className="bg-gray-1 flex h-screen w-full">
      <div className="w-full p-2 md:p-4">
        <div className="border-gray-6 mt-2 grid gap-1 border-b md:mt-4">
          <H3>{format(new Date(appointment.startDate), 'cccc, MMMM do')}</H3>
          <Link to={`/tenants/${tenantId}/appointments/${appointmentId}`}>
            <H2>{appointment.name}</H2>
          </Link>
        </div>
        <AppointmentOutputsTabNav appointmentId={appointmentId} />
        <div className="mt-2 w-full">
          <Outlet />
        </div>
        <div className="mt-8 flex gap-16 px-8 py-2"></div>
      </div>
    </div>
  );
};
