import getInitials from 'initials';
import { useMemo } from 'react';
import uniqolor from 'uniqolor';

import { tv } from '@eluve/components';
import { Avatar, AvatarFallback } from '@eluve/components';

import { TooltipLabel } from './TooltipLabel';

interface AvatarWithInitialsProps {
  /** Use to generate initials */
  name: string;
  /** Use to set unique background color to the avatar */
  id?: string;
}

const initialVariant = tv({
  base: 'text-xs font-semibold',
  variants: {
    color: {
      dark: 'text-gray-1 mix-blend-overlay',
      light: 'text-gray-12/60 mix-blend-color-burn',
    },
  },
});

export const AvatarWithInitials: React.FC<AvatarWithInitialsProps> = ({
  name,
  id,
}) => {
  const initials = useMemo(() => getInitials(name), [name]);
  const userId = useMemo(() => id ?? name, [id, name]);
  const bgColor = useMemo(
    () => uniqolor(userId, { lightness: [50, 85] }),
    [userId],
  );

  return (
    <TooltipLabel label={name}>
      <Avatar>
        <AvatarFallback style={{ backgroundColor: bgColor.color }}>
          <span
            className={initialVariant({
              color: bgColor.isLight ? 'light' : 'dark',
            })}
          >
            {initials}
          </span>
        </AvatarFallback>
      </Avatar>
    </TooltipLabel>
  );
};
