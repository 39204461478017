import React from 'react';

import {
  InterimResultLoading,
  TranscriptText,
  TranscriptWithHighlights,
  TranscriptionWarning,
} from '@eluve/blocks';
import { Box } from '@eluve/components';
import { useBillingCodeStore } from '@eluve/frontend-feature-appointment';
import { graphql } from '@eluve/graphql.tada';

import { useAutoScrollContainer } from '../hooks/useAutoScrollContainer';
import { useInSessionTranscript } from '../machines/transcription/hooks/useInSessionTranscript';

export const appointmentTranscriptFragment = graphql(`
  fragment appointmentTranscription on Appointments {
    __typename
    transcription {
      __typename
      transcript
    }
  }
`);

export const AppointmentInSessionTranscript: React.FC = () => {
  const { interimTranscript, isTranscriptionDegraded, transcript } =
    useInSessionTranscript();

  const containerRef = useAutoScrollContainer([transcript, interimTranscript]);

  const highlightedExcerpts = useBillingCodeStore(
    (s) => s.highlightedTranscriptExcerpts,
  );

  return (
    <>
      {isTranscriptionDegraded && <TranscriptionWarning />}

      <Box className="h-full overflow-y-auto" ref={containerRef}>
        <TranscriptWithHighlights
          transcript={transcript}
          excerpts={highlightedExcerpts ?? []}
        />
        <TranscriptText interim>
          {interimTranscript ?? <InterimResultLoading />}
        </TranscriptText>
      </Box>
    </>
  );
};
