import { InputLabel } from '@eluve/components';

import { TemplateSelector } from './TemplateSelector';
import { useAppointmentTemplates } from './useAppointmentTemplates';

interface NewAppointmentTemplateProps {
  tenantId: string;
  appointmentId: string;
  showHeader?: boolean;
}

export const NewAppointmentTemplateSelector: React.FC<
  NewAppointmentTemplateProps
> = ({ tenantId, appointmentId, showHeader = true }) => {
  const {
    initialLlmOutputTemplateId,
    initialPromptTemplateId,
    updateAppointmentTemplates,
  } = useAppointmentTemplates();

  return (
    <InputLabel label={showHeader ? 'Template' : null}>
      <TemplateSelector
        selectedPromptTemplateId={initialPromptTemplateId ?? undefined}
        onSelectionChanged={({
          initialPromptTemplateId,
          initialOutputTemplateId,
        }) =>
          updateAppointmentTemplates({
            variables: {
              tenantId,
              id: appointmentId,
              initialPromptTemplateId,
              initialLlmOutputTemplateId: initialOutputTemplateId,
            },
          })
        }
        selectedOutputTemplateId={initialLlmOutputTemplateId ?? undefined}
      />
    </InputLabel>
  );
};
