import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Switch } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const tenantPromptTemplateFragment = graphql(`
  fragment TenantPromptTemplate on TenantPromptTemplates @_unmask {
    __typename
    tenantId
    promptTemplateId
    isEnabled
    updatedAt
  }
`);

const setTenantPromptTemplateMutation = graphql(
  `
    mutation setTenantPromptTemplate(
      $tenantId: uuid!
      $promptTemplateId: uuid!
      $isEnabled: Boolean!
    ) {
      insertTenantPromptTemplatesOne(
        object: {
          tenantId: $tenantId
          promptTemplateId: $promptTemplateId
          isEnabled: $isEnabled
        }
        onConflict: {
          constraint: tenant_prompt_templates_pkey
          updateColumns: [isEnabled]
        }
      ) {
        ...TenantPromptTemplate
      }
    }
  `,
  [tenantPromptTemplateFragment],
);

export interface TenantPromptTemplateToggleProps {
  tenantId: string;
  name: string;
  promptTemplateId: string;
}

export const TenantPromptTemplateToggle: React.FC<
  TenantPromptTemplateToggleProps
> = ({ tenantId, name, promptTemplateId }) => {
  const [updateTenantPromptTemplate] = useMutation(
    setTenantPromptTemplateMutation,
    {
      onCompleted: () =>
        toast.success(`Access to prompt template ${name} updated`),
      onError: () =>
        toast.error(`Failed to update access to prompt template ${name}`),
      optimisticResponse: (data) => ({
        insertTenantPromptTemplatesOne: {
          __typename: 'TenantPromptTemplates' as const,
          updatedAt: new Date().toISOString(),
          ...data,
        },
      }),
    },
  );

  const submit = async (isEnabled: boolean) => {
    try {
      await updateTenantPromptTemplate({
        context: {
          headers: {
            'x-hasura-role': 'eluve-admin',
          },
        },
        variables: {
          tenantId,
          promptTemplateId,
          isEnabled,
        },
      });
    } catch (e) {
      toast.error(`Failed to update access to prompt template ${name}`);
    }
  };

  const data = useCompleteFragment({
    fragment: tenantPromptTemplateFragment,
    key: {
      promptTemplateId,
      tenantId,
    },
    strict: false,
  });

  return (
    <Switch
      isCompact
      onCheckedChange={(isChecked) => submit(isChecked)}
      checked={data?.isEnabled ?? false}
    />
  );
};
