import { useMutation, useSubscription } from '@apollo/client';
import noop from 'lodash/noop';
import { Check, LogOut, Star } from 'lucide-react';
import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Button,
  Card,
  CardContent,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  HStack,
  P,
  Progress,
  Select,
  SelectContent,
  SelectTrigger,
  Separator,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  VStack,
  textStyles,
} from '@eluve/components';
import {
  allCompletedSoapNotesFragment,
  listenAppointmentStatusSubscription,
  updateSoapNote,
  useAllSummaries,
  useAppointmentActivity,
  useIsAppointmentSigned,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { PromptTemplateSelectItem } from '@eluve/frontend-feature-user-settings';
import { usePromptTemplates } from '@eluve/frontend-feature-user-settings';
import { graphql } from '@eluve/graphql.tada';
import {
  DynamicArtifactRenderer,
  SummaryFeedbackModal,
  SummarySectionMarkdown,
} from '@eluve/smart-blocks';
import { AppointmentSummary, AppointmentSummaryKeys } from '@eluve/utils';

import { useListenForAnySummaries } from './useListenForAnySummaries';

const listenAllSoapNotes = graphql(
  `
    subscription listenAllSoapNotes($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...AllCompletedSoapNotes
      }
    }
  `,
  [allCompletedSoapNotesFragment],
);

type TemplateDetails = { promptTemplateId: string; outputTemplateId?: string };
type PromptsCarouselProps = {
  selectedLlmTemplates: TemplateDetails[];
  setSelectedLlmTemplates: (
    value: React.SetStateAction<TemplateDetails[]>,
  ) => void;
};

const PromptsCarousel: React.FC<PromptsCarouselProps> = ({
  selectedLlmTemplates,
  setSelectedLlmTemplates,
}) => {
  const {
    activeOutputTemplates: outputTemplates,
    activeSOAPPromptTemplates: promptTemplates,
    defaultOutputTemplateId,
    userSettingsPromptTemplateId,
  } = usePromptTemplates();
  return (
    <Carousel
      opts={{
        align: 'start',
      }}
      className="w-full"
    >
      <CarouselContent>
        {promptTemplates.map((promptTemplate) => {
          let selectedOutputTemplateId: string | undefined = undefined;
          if (promptTemplate.outputType === 'DYNAMIC_OUTPUT') {
            selectedOutputTemplateId =
              selectedLlmTemplates.find(
                (t) => t.promptTemplateId === promptTemplate.id,
              )?.outputTemplateId ??
              defaultOutputTemplateId ??
              outputTemplates[0]?.id;
          }
          return (
            <CarouselItem
              key={promptTemplate.id}
              className="md:basis-1/2 lg:basis-1/4"
            >
              <Card className="h-full">
                <CardContent className="flex h-full flex-col">
                  <div className="flex items-start border-b p-2">
                    {userSettingsPromptTemplateId === promptTemplate.id && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger className="hover:cursor-default">
                            <Star className="text-brand-8 h-5" />
                          </TooltipTrigger>
                          <TooltipContent>
                            This is set as your default template. You can change
                            it in your settings.
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                    <p>{promptTemplate.name}</p>
                  </div>
                  <p
                    className={textStyles.body({
                      className: 'overflow-scroll px-2',
                      size: 's',
                    })}
                  >
                    <VStack>
                      {promptTemplate.description ?? ''}
                      {promptTemplate.outputType === 'DYNAMIC_OUTPUT' && (
                        <Select
                          value={selectedOutputTemplateId}
                          onValueChange={(value) => {
                            setSelectedLlmTemplates((prev) => {
                              const newValues = prev.filter(
                                (d) => d.promptTemplateId !== promptTemplate.id,
                              );
                              const newTemplate = {
                                promptTemplateId: promptTemplate.id!,
                                outputTemplateId: value,
                              };
                              return [...newValues, newTemplate];
                            });
                          }}
                        >
                          <SelectTrigger>
                            {(() => {
                              const templateOutputId =
                                selectedLlmTemplates.find(
                                  (t) =>
                                    t.promptTemplateId === promptTemplate.id,
                                )?.outputTemplateId ??
                                defaultOutputTemplateId ??
                                outputTemplates[0]?.id;
                              return (
                                outputTemplates.find(
                                  (template) =>
                                    template.id === templateOutputId,
                                )?.name ?? ''
                              );
                            })()}
                          </SelectTrigger>
                          <SelectContent>
                            {outputTemplates.map((template) => (
                              <PromptTemplateSelectItem
                                key={template.id}
                                templateId={template.id}
                                templateName={template.name}
                                templateDescription={null}
                              />
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    </VStack>
                  </p>
                  <Button
                    variant="gray"
                    className="mt-auto rounded-none"
                    onClick={() => {
                      setSelectedLlmTemplates((prev) => {
                        if (
                          selectedLlmTemplates.find(
                            (selectedLlmTemplate) =>
                              selectedLlmTemplate.promptTemplateId ===
                              promptTemplate.id,
                          )
                        ) {
                          return prev.filter(
                            (id) => id.promptTemplateId !== promptTemplate.id,
                          );
                        }
                        return [
                          ...prev,
                          {
                            promptTemplateId: promptTemplate.id!,
                            outputTemplateId: selectedOutputTemplateId,
                          },
                        ];
                      });
                    }}
                  >
                    {selectedLlmTemplates.find(
                      (selectedLlmTemplate) =>
                        selectedLlmTemplate.promptTemplateId ===
                        promptTemplate.id,
                    ) ? (
                      <Check className="text-brand-8 h-5 text-white" />
                    ) : (
                      'Select'
                    )}
                  </Button>
                </CardContent>
              </Card>
            </CarouselItem>
          );
        })}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
};

export const SummaryComparison: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSummaryId = searchParams.get('summaryId') ?? undefined;
  const [progress, setProgress] = useState(0);
  const {
    activeOutputTemplates: outputTemplates,
    activeSOAPPromptTemplates: promptTemplates,
  } = usePromptTemplates();

  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const isNoteSigned = useIsAppointmentSigned();

  const [selectedLlmTemplates, setSelectedLlmTemplates] = useState<
    TemplateDetails[]
  >([]);

  const apiClient = useApiClient();

  const { isAnySummarizationInProgress } = useAppointmentActivity();

  const currentSummary = useSummary();

  const [updateSummary] = useMutation(updateSoapNote, {
    optimisticResponse: (data) => {
      return {
        updateHumanOutputsByPk: {
          __typename: 'HumanOutputs' as const,
          id: data.humanOutputId,
          content: data.content,
          editedFromLlmOutputId: data.llmOutputId,
        },
      };
    },
  });

  const generateSummaries = async () => {
    setProgress(0);
    const internvalId = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(internvalId);
        }
        return prev + 1;
      });
    }, 750);

    if (!selectedLlmTemplates.length) {
      return;
    }

    await apiClient.llm.summarizeAppointmentWithMultipleTemplates({
      body: {
        summarizationDetails: selectedLlmTemplates,
      },
      params: {
        tenantId,
        appointmentId,
      },
    });

    setSelectedLlmTemplates([]);
  };

  const showFeedback = true;

  useListenForAnySummaries();

  useSubscription(listenAppointmentStatusSubscription, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  useSubscription(listenAllSoapNotes, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const { summaries } = useAllSummaries();

  const filteredSummaries = (summaries ?? [])
    .filter((s) => s.isSummaryAvailable)
    .sort((a, b) => {
      if (!a.summarizationCompletedAt || !b.summarizationCompletedAt) {
        // if value is undefined, push to end of list
        return -1;
      }
      return (
        new Date(a.summarizationCompletedAt).valueOf() -
        new Date(b.summarizationCompletedAt).valueOf()
      );
    });

  return (
    <VStack gap={6} className="container my-6">
      <Link to={`/tenants/${tenantId}/appointments/${appointmentId}`}>
        <Button variant="outline">
          Return to your session <LogOut className="ml-2" />
        </Button>
      </Link>
      <VStack gap={6}>
        <PromptsCarousel
          selectedLlmTemplates={selectedLlmTemplates}
          setSelectedLlmTemplates={setSelectedLlmTemplates}
        />
        <HStack justify="center">
          <Button
            disabled={
              !selectedLlmTemplates.length ||
              Boolean(isAnySummarizationInProgress)
            }
            type="submit"
            variant="gray"
            className="relative w-52 border-none p-0"
            onClick={generateSummaries}
          >
            <div className="absolute flex h-full w-full items-center justify-center">
              <div className="absolute flex h-full w-full items-center justify-center">
                <Progress
                  value={progress}
                  className={`absolute left-0 top-0 h-10 w-full rounded-sm ${isAnySummarizationInProgress ? 'opacity-30' : 'opacity-0'}`}
                />
              </div>
              <div className="absolute flex h-full w-full items-center justify-center">
                {isAnySummarizationInProgress
                  ? 'Generating...'
                  : 'Generate Summaries'}
              </div>
            </div>
          </Button>
        </HStack>

        <Separator />

        <Tabs
          className="w-full"
          value={selectedSummaryId ?? currentSummary?.llmOutputId ?? undefined}
        >
          <TabsList className="flex h-full w-full flex-wrap gap-2 bg-transparent">
            {filteredSummaries.map((llmSummary, index) => {
              const promptTemplateId = llmSummary.promptTemplateId;
              const promptTemplate = promptTemplates?.find(
                (p) => p.id === promptTemplateId,
              );
              const promptTemplateName =
                promptTemplate?.name ?? `Inactive Template`;
              const outputTemplateId =
                llmSummary.summary.type === 'DYNAMIC'
                  ? llmSummary.outputTemplateId
                  : undefined;
              const outputTemplateName = outputTemplates?.find(
                (t) => t.id === outputTemplateId,
              )?.name;
              return (
                <TabsTrigger
                  key={llmSummary.summaryId}
                  value={llmSummary.summaryId}
                  onClick={() => {
                    const urlSearchParams = new URLSearchParams();
                    urlSearchParams.set('summaryId', llmSummary.summaryId);
                    setSearchParams(urlSearchParams, {
                      replace: true,
                    });
                  }}
                  className="bg-gray-4 flex border data-[state=active]:shadow-md"
                >
                  {`${promptTemplateName} ${outputTemplateName ? '(' + outputTemplateName + ')' : ''} (#${index + 1})`}
                  {llmSummary.summaryId === currentSummary?.llmOutputId && (
                    <Check className="text-brand-8 h-5" />
                  )}
                </TabsTrigger>
              );
            })}
          </TabsList>
          {filteredSummaries.map((llmSummary) => {
            const canSetNewSummary =
              !isNoteSigned &&
              llmSummary.summaryId !== currentSummary?.llmOutputId;

            return (
              <TabsContent
                value={llmSummary.summaryId}
                key={llmSummary.summaryId}
              >
                <div className="m-4 flex flex-col items-center">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          type="submit"
                          disabled={!canSetNewSummary}
                          variant="gray"
                          onClick={async () => {
                            await updateSummary({
                              variables: {
                                content: (llmSummary.summary.type === 'SOAP'
                                  ? llmSummary.summary.data
                                  : llmSummary.rawContent) as AppointmentSummary,
                                llmOutputId: llmSummary.summaryId,
                                tenantId,
                                humanOutputId: currentSummary!.humanOutputId!,
                              },
                            });
                          }}
                        >
                          Use this summary
                        </Button>
                      </TooltipTrigger>
                      {!canSetNewSummary && (
                        <TooltipContent>
                          {isNoteSigned && 'This note is already signed'}
                          {!isNoteSigned &&
                            llmSummary.summaryId ===
                              currentSummary?.llmOutputId &&
                            'This note is already set as current summary'}
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </TooltipProvider>

                  <P className="mb-2 mt-2">
                    Template ID: {llmSummary.promptTemplateId}
                  </P>
                </div>
                <VStack>
                  {match(llmSummary.summary)
                    .with({ type: 'SOAP' }, (soapSummary) =>
                      soapSummary.data
                        ? Object.entries(soapSummary.data).map(
                            ([summaryKey, summaryValue]) => {
                              return (
                                <SummarySectionMarkdown
                                  appointmentId={appointmentId}
                                  key={summaryKey}
                                  summaryKey={
                                    summaryKey as AppointmentSummaryKeys
                                  }
                                  sectionTitle={summaryKey}
                                  content={summaryValue ?? ''}
                                  disabled={true}
                                  handleContentChange={noop}
                                />
                              );
                            },
                          )
                        : null,
                    )
                    .otherwise((dynamicSummary) => (
                      <DynamicArtifactRenderer
                        isReadonly={true}
                        blocks={dynamicSummary.blocks}
                      />
                    ))}

                  {showFeedback && (
                    <HStack justify="end">
                      <SummaryFeedbackModal
                        appointmentId={appointmentId}
                        llmOutputId={llmSummary.summaryId}
                      />
                    </HStack>
                  )}
                </VStack>
              </TabsContent>
            );
          })}
        </Tabs>
      </VStack>
    </VStack>
  );
};
