import { useApolloClient, useSuspenseQuery } from '@apollo/client';
import {
  CalendarClockIcon,
  ChevronRight,
  ContactRoundIcon,
  User,
  UserCogIcon,
} from 'lucide-react';
import { Suspense } from 'react';
import { Link } from 'react-router-dom';

import { Box, Card, H5, P, Skeleton, cn } from '@eluve/components';
import { formatToLocale } from '@eluve/date-utils';

import { GetTenantsList } from '../operations';

const listItemClassName = cn(
  'w-full min-w-[320px] grid items-center gap-2',
  'md:grid-cols-[40px_1fr_2fr_40px] lg:grid-cols-[40px_1fr_1fr_40px]',
  'grid-cols-[40px_1fr_40px]',
);

const listItemInfoClassName = cn(
  'grid grid-cols-3 gap-2 items-center justify-items-center text-gray-9',
  '*:text-center  *:whitespace-nowrap',
  'md:row-start-auto md:col-span-1 md:border-t-0 md:pt-0 md:text-gray-10',
  'row-start-2 col-span-3 border-t border-gray-5 pt-2',
);

interface TenantListItemProps {
  name: React.ReactNode;
  role: React.ReactNode;
  memberSince: React.ReactNode;
  tenantCreatedAt: React.ReactNode;
}

const TenantListItem: React.FC<TenantListItemProps> = ({
  name,
  role,
  memberSince,
  tenantCreatedAt,
}) => {
  return (
    <Card className={cn('px-2 py-3 md:py-4', listItemClassName)}>
      <Box center className="border-r-gray-3 mr-1 border-r pr-1">
        <User className="text-accent/80" />
      </Box>
      <H5>{name}</H5>
      <div className={listItemInfoClassName}>
        <P>{role}</P>
        <Box hStack className="gap-2 justify-self-start md:justify-self-center">
          <ContactRoundIcon className="text-gray-9 h-5 w-5 md:hidden" />
          <P>{memberSince}</P>
        </Box>
        <Box hStack className="gap-2 justify-self-start md:justify-self-center">
          <CalendarClockIcon className="text-gray-9 h-5 w-5 md:hidden" />
          <P>{tenantCreatedAt}</P>
        </Box>
      </div>
      <ChevronRight className="border-l-gray-3 text-accent h-6 w-10 border-l-2 pl-2" />
    </Card>
  );
};

const TenantsListContent: React.FC = () => {
  const { data } = useSuspenseQuery(GetTenantsList);
  const client = useApolloClient();

  return (
    <>
      {data.assignedTenantUsers.map((tenant) => {
        if (!tenant.tenantId) {
          return null;
        }

        return (
          <Link
            to={tenant.tenantId}
            key={tenant.tenantId}
            className={cn(
              'group drop-shadow-none hover:drop-shadow-lg',
              'transition-all duration-200 ease-in-out',
            )}
            onClick={() => {
              // Clear the apollo cache if we're switching tenants
              client.resetStore();
            }}
          >
            <TenantListItem
              name={tenant.name}
              role={tenant.role}
              memberSince={formatToLocale(tenant.memberSince, { noTime: true })}
              tenantCreatedAt={formatToLocale(tenant.tenantCreatedAt, {
                noTime: true,
              })}
            />
          </Link>
        );
      })}
    </>
  );
};

export const TenantsList = () => {
  return (
    <div className="flex max-h-full w-full flex-col gap-2 overflow-auto p-2">
      <div className={cn('!hidden px-5 py-1 md:!grid', listItemClassName)}>
        <div />
        <P>Tenant</P>
        <div className={listItemInfoClassName}>
          <Box hStack className="gap-4">
            <UserCogIcon className="text-gray-9 h-5 w-5" />
            <P>Role</P>
          </Box>
          <Box hStack className="gap-4">
            <ContactRoundIcon className="text-gray-9 h-5 w-5" />
            <P>Member Since</P>
          </Box>
          <Box hStack className="gap-4">
            <CalendarClockIcon className="text-gray-9 h-5 w-5" />
            <P>Created at</P>
          </Box>
        </div>
      </div>
      <Suspense
        fallback={
          <>
            {[...Array(4)].map((_, index) => (
              <TenantListItem
                key={index}
                name={<Skeleton className="bg-gray-4 h-4 w-[200px]" />}
                role={
                  <Box fullWidth center>
                    <Skeleton className="bg-gray-4 h-4 w-[70px]" />
                  </Box>
                }
                memberSince={
                  <Box fullWidth center>
                    <Skeleton className="bg-gray-4 h-4 w-[70px]" />
                  </Box>
                }
                tenantCreatedAt={
                  <Box fullWidth center>
                    <Skeleton className="bg-gray-4 h-4 w-[70px]" />
                  </Box>
                }
              />
            ))}
          </>
        }
      >
        <TenantsListContent />
      </Suspense>
    </div>
  );
};
