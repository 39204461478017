import React from 'react';

import { SelectContent } from '@eluve/components';

import { PromptTemplateSelectItem } from './PromptTemplateSelectItem';
import { usePromptTemplates } from './usePromptTemplates';

interface PromptTemplateSelectContentProps {
  additionalOptions?: React.ReactNode;
}

export const OutputTemplateSelectContent: React.FC<
  PromptTemplateSelectContentProps
> = ({ additionalOptions }) => {
  const { activeOutputTemplates } = usePromptTemplates();

  return (
    <SelectContent className="max-h-[50vh] overflow-y-auto">
      {activeOutputTemplates?.map((template) => (
        <PromptTemplateSelectItem
          key={template.id}
          templateId={template.id ?? ''}
          templateName={template.name ?? ''}
          templateDescription={null}
        />
      ))}
      {additionalOptions}
    </SelectContent>
  );
};
