import { HTMLProps, forwardRef } from 'react';

import { tv } from '@eluve/components';

const transcriptTextVariants = tv({
  base: 'text-gray-11 whitespace-pre-line align-baseline font-normal !leading-[2.5rem]',
  variants: {
    interim: {
      true: 'text-gray-8',
    },
    widgetTranscript: {
      true: '!leading-[1.5rem]',
    },
  },
});

interface TranscriptTextProps extends HTMLProps<HTMLParagraphElement> {
  interim?: boolean;
  widgetTranscript?: boolean;
}

export const TranscriptText = forwardRef<
  HTMLParagraphElement,
  TranscriptTextProps
>(({ children, interim, widgetTranscript, className, ...props }, ref) => {
  return (
    <p
      ref={ref}
      {...props}
      className={transcriptTextVariants({
        interim,
        widgetTranscript,
        className,
      })}
    >
      {children}
    </p>
  );
});
