import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import {
  eluveAdminHasuraContext,
  useCompleteFragment,
} from '@eluve/apollo-client';
import { VStack } from '@eluve/components';
import { ColDefBuilder, DataTable, H3 } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

type OutputTemplateRow = {
  id: string;
  name: string;
  description: string;
  outputType: string;
  tenantId: string;
  templateUpdatedAt: string;
  accessUpdatedAt: string;
};

const userOutputTemplateColumns = new ColDefBuilder<OutputTemplateRow>()
  .linkSortable('name', (row) => `/admin/output-templates/${row.id}`)
  .defaultSortable('description')
  .defaultSortable('outputType', 'Output Type')
  .dateSortable('templateUpdatedAt', 'Template Updated At')
  .dateSortable('accessUpdatedAt', 'Access Updated At')
  .build();

const llmOutputTemplateFragment = graphql(`
  fragment LlmOutputTemplate on LlmOutputTemplates @_unmask {
    __typename
    id
    name
    description
    llmOutputType
    updatedAt
  }
`);

const userLlmOutputTemplateFragment = graphql(
  `
    fragment UserLlmOutputTemplate on UsersLlmOutputTemplates @_unmask {
      __typename
      tenantId
      llmOutputTemplateId
      updatedAt
      userId
      llm_output_template {
        ...LlmOutputTemplate
      }
    }
  `,
  [llmOutputTemplateFragment],
);

const usersLlmOutputTemplatesFragment = graphql(
  `
    fragment UsersLlmOutputTemplates on Tenants @_unmask {
      id
      __typename
      users_llm_output_templates {
        ...UserLlmOutputTemplate
      }
    }
  `,
  [userLlmOutputTemplateFragment],
);

const getLlmOutputTemplatesQuery = graphql(
  `
    query getLlmOutputTemplates($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...UsersLlmOutputTemplates
      }
    }
  `,
  [usersLlmOutputTemplatesFragment],
);

const ImportedLlmOutputTemplates: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;
  const data = useCompleteFragment({
    fragment: usersLlmOutputTemplatesFragment,
    key: {
      id: tenantId,
    },
  });

  const rows = useMemo(() => {
    return (data?.users_llm_output_templates ?? []).map<OutputTemplateRow>(
      (llmOutputTemplate) => ({
        id: llmOutputTemplate.llm_output_template.id,
        name: llmOutputTemplate.llm_output_template.name,
        tenantId,
        description: llmOutputTemplate.llm_output_template.description ?? '',
        outputType: llmOutputTemplate.llm_output_template.llmOutputType,
        accessUpdatedAt: llmOutputTemplate.updatedAt,
        templateUpdatedAt: llmOutputTemplate.llm_output_template.updatedAt,
      }),
    );
  }, [data, tenantId]);

  return (
    <VStack className="grid gap-2">
      <VStack>
        <H3>Imported LLM Output Templates</H3>
      </VStack>
      <DataTable
        data={rows}
        columns={userOutputTemplateColumns}
        isPaginated={false}
        enableGlobalSearch
        isCompact
        initialSortingState={[
          {
            id: 'templateUpdatedAt',
            desc: true,
          },
        ]}
      />
    </VStack>
  );
};

export const AdminTenantImportedOutputTemplatesPage: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;

  useSuspenseQuery(getLlmOutputTemplatesQuery, {
    variables: {
      tenantId,
    },
    context: eluveAdminHasuraContext,
  });
  return (
    <VStack>
      <ImportedLlmOutputTemplates />
    </VStack>
  );
};
