import { Box, Button, ReskinFooter } from '@eluve/components';
import {
  useAppointmentId,
  useAppointmentStatus,
} from '@eluve/frontend-appointment-hooks';

import { TranscriptionWaveform } from '../machines/transcription/components/TranscriptionWaveform';
import { useAppointmentTabNav } from '../useAppointmentTabNav';

import { AppointmentTranscriptionPageControls } from './AppointmentTranscriptionPageControls';

export const AppointmentTranscriptionFooter: React.FC = () => {
  const appointmentId = useAppointmentId();
  const { tab } = useAppointmentTabNav();
  const status = useAppointmentStatus() as string;

  if (tab !== 'transcript') return null;

  return (
    <ReskinFooter>
      <Box
        spaceBetween
        className="mx-auto w-full max-w-[1024px] items-center gap-2"
      >
        <TranscriptionWaveform />

        {status === 'COMPLETED' ? (
          <Button variant="outline" disabled>
            Session Completed
          </Button>
        ) : (
          <AppointmentTranscriptionPageControls appointmentId={appointmentId} />
        )}
      </Box>
    </ReskinFooter>
  );
};
