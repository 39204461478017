import { AppointmentModel } from '../../models/appointment';

import { Appointment } from './types';

export const convertMovementXDataToAppointmentModel = (
  data: Appointment,
): AppointmentModel => {
  const { appointment_scheduled: appointmentSchedule } = data;

  const timezone = appointmentSchedule?.session_type?.timezone;

  const appointmentModel: AppointmentModel = {
    externalId: `${data.id}`,
    externalPatientId: `${appointmentSchedule?.patient?.id}`,
    firstName: appointmentSchedule?.patient?.user?.first_name ?? '',
    lastName: appointmentSchedule?.patient?.user?.last_name ?? '',
    startTime: appointmentSchedule?.start_time
      ? new Date(appointmentSchedule?.start_time).toISOString()
      : '',
    endTime: appointmentSchedule?.end_time
      ? new Date(appointmentSchedule?.end_time).toISOString()
      : '',
    name: appointmentSchedule?.session_type?.title ?? '',
    description: data.notes ?? '',
    status: data?.is_canceled ? 'CANCELLED' : undefined,
    isArchived: Boolean(data?.is_canceled),
    isImportable: true, // Haven't comme across a case where MX returns unimportable appointments
    sessionType: appointmentSchedule?.session_type?.title ?? '',
    rawEhrData: data,
  };

  return appointmentModel;
};
