import { useQuery } from '@apollo/client';
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const outputTemplateNameFragment = graphql(`
  fragment OutputTemplateName on LlmOutputTemplates {
    __typename
    id
    name
  }
`);

const outputTemplateQuery = graphql(
  `
    query getOutputTemplateName($id: uuid!) {
      llmOutputTemplatesByPk(id: $id) {
        ...OutputTemplateName
      }
    }
  `,
  [outputTemplateNameFragment],
);

export const OutputTemplateBreadcrumbs: React.FC = () => {
  const { outputTemplateId } = useParams() as {
    outputTemplateId?: string;
  };

  const outputTemplate = useCompleteFragment({
    fragment: outputTemplateNameFragment,
    key: {
      id: outputTemplateId ?? '',
    },
    strict: false,
  });

  useQuery(outputTemplateQuery, {
    variables: {
      id: outputTemplateId ?? '',
    },
    fetchPolicy: 'cache-first',
    skip: !outputTemplateId || !outputTemplate?.name,
    context: {
      headers: {
        'x-hasura-role': 'eluve-admin',
      },
    },
  });

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <NavLink to="/admin/output-templates">Output Templates</NavLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {outputTemplateId && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <NavLink to={`/admin/output-templates/${outputTemplateId}`}>
                  {outputTemplate?.name}
                </NavLink>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
