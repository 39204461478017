import { useLocalStorage } from '@uidotdev/usehooks';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useKey, useMount } from 'react-use';
import { toast } from 'sonner';

import { useTenantIdFromParams } from '@eluve/session-helpers';

const DefaultPrivacyBlurAmount = '0.5rem';
const PrivacyVariableName = '--privacy-blur-amount';

const PrivacyModeToggleContext = createContext<{
  privacyMode?: boolean;
  setPrivacyMode: (privacyMode: boolean) => void;
} | null>(null);

export const usePrivacyModeToggle = () => {
  const context = useContext(PrivacyModeToggleContext);
  if (!context) {
    throw new Error(
      'usePrivacyModeToggle must be used within a PrivacyToggleProvider',
    );
  }
  return context;
};

interface PrivacyToggleProviderProps {
  blurAmount?: string;
}

/**
 * Toggles the privacy mode on and off.
 * @param blurAmount optional:  The amount of blur to apply to the page when privacy mode is enabled.
 * @default 0.5rem
 *
 * The privacy mode is toggled on and off by pressing the Shift + Ctrl/Cmd + P keys.
 * It sets css variable --privacy-blur-amount to the blurAmount when privacy mode is enabled and 0px when disabled.
 *
 * The css variable --privacy-blur-amount must be used in to apply the blur effect on the elements.
 * @example
 * ```css
 * .privacy {
 *   filter: blur(var(--privacy-blur-amount));
 * }
 * ```
 *
 * ```tsx
 * <p className="privacy">This is a private paragraph</p>
 * ```
 */
export const PrivacyToggleProvider: React.FC<
  PropsWithChildren<PrivacyToggleProviderProps>
> = ({ blurAmount = DefaultPrivacyBlurAmount, children }) => {
  const tenantId = useTenantIdFromParams() ?? 'root';
  const [privacyMode, setPrivacyMode] = useLocalStorage(
    `privacy-mode-${tenantId}`,
    false,
  );

  useMount(() => {
    const rootElement = document.documentElement;
    rootElement.style.setProperty(
      PrivacyVariableName,
      privacyMode ? blurAmount : '0px',
    );
  });

  const handlePrivacyMode = useCallback(
    (enable: boolean) => {
      const rootElement = document.documentElement;

      if (enable) {
        rootElement.style.setProperty(PrivacyVariableName, '0px');
        toast.success('Privacy mode disabled', {
          icon: <EyeIcon />,
          id: 'privacy-mode',
        });
        setPrivacyMode(false);
        return;
      }

      rootElement.style.setProperty(PrivacyVariableName, blurAmount);
      toast.success('Privacy mode enabled', {
        icon: <EyeOffIcon />,
        id: 'privacy-mode',
      });
      setPrivacyMode(true);
    },
    [blurAmount, setPrivacyMode],
  );

  useKey(
    (event) => {
      if (event.key !== 'p' && event.key !== 'P') {
        return false;
      }

      if (!event.shiftKey || event.ctrlKey || event.metaKey || event.altKey)
        return false;

      const activeElement =
        document.activeElement ?? (event.target as HTMLElement);
      if (!activeElement) return true;

      if (['INPUT', 'TEXTAREA'].includes(activeElement.tagName)) {
        return false;
      }

      if ((activeElement as HTMLParagraphElement).isContentEditable) {
        return false;
      }

      return true;
    },
    () => {
      const rootElement = document.documentElement;
      const privacyBlurAmount =
        rootElement.style.getPropertyValue(PrivacyVariableName);

      handlePrivacyMode(privacyBlurAmount === blurAmount);
    },
    {
      event: 'keydown',
    },
  );

  const value = useMemo(
    () => ({
      privacyMode: privacyMode ?? false,
      setPrivacyMode: handlePrivacyMode,
    }),
    [privacyMode, handlePrivacyMode],
  );

  return (
    <PrivacyModeToggleContext.Provider value={value}>
      {children}
    </PrivacyModeToggleContext.Provider>
  );
};
