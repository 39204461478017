import { PlayIcon, StopCircleIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { Button, HStack, MicrophoneLevel } from '@eluve/components';
import {
  MicrophoneDeviceSelector,
  useSelectedMicrophoneDevice,
} from '@eluve/smart-blocks';

export interface MicSettingsProps {}

export const MicSettings: React.FC<MicSettingsProps> = () => {
  const [selectedMicId, setSelectedMicId] = useSelectedMicrophoneDevice();
  const [enableMicTest, setEnableMicTest] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  useEffect(
    function runMicTest() {
      if (!enableMicTest) {
        return;
      }

      let localMediaStream: MediaStream | null = null;
      (async () => {
        localMediaStream = await navigator.mediaDevices.getUserMedia({
          audio: selectedMicId
            ? {
                deviceId: selectedMicId,
              }
            : true,
        });

        setMediaStream(localMediaStream);
      })();

      return () => {
        localMediaStream?.getTracks().forEach((track) => {
          track.stop();
        });
      };
    },
    [selectedMicId, enableMicTest],
  );

  return (
    <>
      <HStack>
        <MicrophoneDeviceSelector
          onDeviceValueChange={(deviceId) => {
            toast.success('Microphone preferences changed');
            setSelectedMicId(deviceId);
          }}
          selectedMicId={selectedMicId}
        />
        <Button
          variant="outline"
          onClick={() => setEnableMicTest(!enableMicTest)}
        >
          {enableMicTest ? (
            <>
              <StopCircleIcon className="mr-2 size-4" />
              Stop
            </>
          ) : (
            <>
              <PlayIcon className="mr-2 size-4" />
              Test
            </>
          )}
        </Button>
      </HStack>
      {enableMicTest && (
        <MicrophoneLevel
          stream={mediaStream}
          key={selectedMicId}
          className="w-max"
          variant="compact"
        />
      )}
    </>
  );
};
