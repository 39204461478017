import React from 'react';

import { HStack } from './HStack';

export interface DebugJsonProps {
  value: unknown;
}

/**
 * A useful Dev component when developing a UI to be able to quickly
 * visualize data before properly wiring it up into a proper format
 */
export const DebugJson: React.FC<DebugJsonProps> = ({ value }) => {
  return (
    <HStack className="rounded-md border p-3 text-sm shadow-md">
      <pre>{JSON.stringify(value, null, 2)}</pre>
    </HStack>
  );
};
