import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  Box,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  P,
} from '@eluve/components';
import { passwordRequirements } from '@eluve/utils';

const submissionTypeSchema = z.union([
  z.literal('login'),
  z.literal('register'),
]);

const formSchema = z
  .object({
    email: z.string().email(),
    type: submissionTypeSchema,
    password: passwordRequirements,
    confirmPassword: z.string().optional(),
  })
  .refine(
    (data) =>
      data.type === 'login' ? true : data.password === data.confirmPassword,
    {
      message: 'Passwords do not match',
      path: ['confirmPassword'],
    },
  );

type FormData = z.infer<typeof formSchema>;

export type EmailPasswordAuthFormData = Omit<FormData, 'confirmPassword'>;

export type AuthFormMode = 'login' | 'register';

export interface EmailPasswordAuthFormProps {
  initialEmail?: string;
  initialMode?: AuthFormMode;
  onSubmit: (
    data: EmailPasswordAuthFormData,
  ) => Promise<AuthFormMode | undefined>;
}

export const EmailPasswordAuthForm: React.FC<EmailPasswordAuthFormProps> = ({
  initialEmail,
  initialMode = 'login',
  onSubmit,
}) => {
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: initialEmail ?? '',
      password: '',
      type: initialMode,
    },
  });

  const submissionType = form.watch('type');

  const handleSubmit = async (data: FormData) => {
    const result = await onSubmit(data);
    if (result) {
      form.setValue('type', result);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="w-full space-y-2"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Enter your email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input placeholder="Password" {...field} type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {submissionType === 'register' && (
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm Password</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Confirm Password"
                    {...field}
                    type="password"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <Button type="submit" className="w-full">
          Submit
        </Button>

        <Box hStack>
          <P>
            {submissionType === 'login'
              ? 'Not a member yet?'
              : 'Trying to login?'}
          </P>
          <Button
            variant="link"
            className="px-0"
            type="button"
            onClick={() =>
              form.setValue(
                'type',
                submissionType === 'login' ? 'register' : 'login',
              )
            }
          >
            {submissionType === 'login' ? 'Create an account' : 'Click here'}
          </Button>
        </Box>
      </form>
    </Form>
  );
};
