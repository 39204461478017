import { initGraphQLTada } from 'gql.tada';

import type { introspection } from './graphql-env';

export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    uuid: string;
    bigint: number;
    date: string;
    jsonb: Record<string, any>;
    timestamptz: string;
    timestamp: string;
    citext: string;
    numeric: number;
    ltree: string;
    _text: string;
  };
}>();

export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada';
export { readFragment } from 'gql.tada';
