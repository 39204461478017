import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';

export const appointmentActivityFragment = graphql(`
  fragment appointmentActiveState on Appointments {
    isSummarizationInProgress
    isAnySummarizationInProgress
    isTranscriptionInProgress
    isPendingAudioFileUpload

    appointment_llm_jobs(
      where: { llmOutputType: { _eq: SOAP_NOTE } }
      orderBy: { createdAt: DESC }
      limit: 1
    ) {
      jobStatus
      numAttempts
    }
  }
`);

export const useAppointmentActivity = () => {
  const id = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentActivityFragment,
    key: {
      id,
    },
  });

  const {
    isSummarizationInProgress,
    isTranscriptionInProgress,
    isPendingAudioFileUpload,
    isAnySummarizationInProgress,
  } = data;

  const latestSummaryStatus = data.appointment_llm_jobs?.[0]?.jobStatus;
  const latestSummaryNumAttempts = data.appointment_llm_jobs?.[0]?.numAttempts;

  return {
    latestSummaryNumAttempts,
    latestSummaryStatus,
    isSummarizationInProgress: isSummarizationInProgress ?? false,
    isAnySummarizationInProgress,
    isTranscriptionInProgress:
      Boolean(isTranscriptionInProgress) || Boolean(isPendingAudioFileUpload),
  };
};
