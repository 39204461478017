import noop from 'lodash/noop';
import { useRef } from 'react';
import { match } from 'ts-pattern';

import { useCompleteFragment } from '@eluve/apollo-client';
import { CopyRichTextButton } from '@eluve/blocks';
import { Box } from '@eluve/components';
import {
  appointmentDoctorInteractionFragment,
  appointmentPatientFragment,
  baseAppointmentInfoFragment,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import {
  DynamicArtifactRenderer,
  SummaryFeedbackModal,
  SummarySectionMarkdown,
} from '@eluve/smart-blocks';
import {
  AppointmentSummaryKeys,
  summaryKeyToSectionTitleMap,
} from '@eluve/utils';

import { ClientRecapMailToAction } from './ClientRecapMailToAction';

export const signedAppointmentLayoutFragment = graphql(
  `
    fragment SignedAppointmentLayout on Appointments {
      ...baseAppointmentInfo
      ...appointmentPatient
      ...appointmentDoctorInteraction
    }
  `,
  [
    baseAppointmentInfoFragment,
    appointmentPatientFragment,
    appointmentDoctorInteractionFragment,
  ],
);

interface SignedSummaryResultProps {
  appointmentId: string;
}

export const SignedSummaryResult: React.FC<SignedSummaryResultProps> = ({
  appointmentId,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const appointment = useCompleteFragment({
    fragment: signedAppointmentLayoutFragment,
    key: {
      id: appointmentId,
    },
  });

  const interaction = appointment.doctor_interaction!;

  const currentSummary = useSummary();
  const summary = currentSummary?.summary ?? {};

  return (
    <Box vStack>
      <Box spaceBetween className="w-full items-center p-2">
        <CopyRichTextButton
          getContentElement={() => contentRef.current}
          variant="outline"
          hideContentInTooltip
          className="hover:text-brand-9 gap-2 hover:bg-transparent"
          size="sm"
        >
          Copy Summary
        </CopyRichTextButton>
        <Box hStack className="gap-2">
          <SummaryFeedbackModal
            appointmentId={appointmentId}
            llmOutputId={currentSummary.llmOutputId}
          />
        </Box>
      </Box>
      <Box vStack className="items-stretch gap-4 md:gap-6" ref={contentRef}>
        {match(summary)
          .with({ type: 'SOAP' }, (soap) => {
            const data = soap.data ?? {};
            return (
              <>
                {Object.entries(summaryKeyToSectionTitleMap).map(
                  ([summaryKey, sectionTitle]) => {
                    const summaryValue =
                      data?.[summaryKey as AppointmentSummaryKeys];

                    if (!summaryValue) {
                      return null;
                    }

                    return (
                      <SummarySectionMarkdown
                        appointmentId={appointmentId}
                        key={summaryKey}
                        summaryKey={summaryKey as AppointmentSummaryKeys}
                        sectionTitle={sectionTitle}
                        content={summaryValue ?? ''}
                        disabled={true}
                        handleContentChange={noop}
                        additionalActions={
                          summaryKey === 'CLIENT_RECAP'
                            ? [ClientRecapMailToAction]
                            : undefined
                        }
                      />
                    );
                  },
                )}
              </>
            );
          })
          .otherwise((summary) => (
            <DynamicArtifactRenderer
              blocks={summary.blocks}
              isReadonly={true}
            />
          ))}

        {interaction.additionalNotes && (
          <SummarySectionMarkdown
            appointmentId={appointmentId}
            key="AdditionalNotes"
            // TODO(jesse)[ELU-949]: This should be presentational only and doesn't need to reuse
            // the same component. The fact that we're passing in noop is a red flag
            summaryKey={`AdditionalNotes` as any}
            sectionTitle="Additional Notes"
            content={interaction.additionalNotes}
            disabled={true}
            handleContentChange={noop}
          />
        )}
      </Box>
    </Box>
  );
};
