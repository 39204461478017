import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSession } from '@eluve/session-helpers';

export const SettingsPage: React.FC = () => {
  const tenantId =
    useSession()['https://hasura.io/jwt/claims']['x-hasura-tenant-id'];

  const to = tenantId ? `/tenants/${tenantId}/settings` : '/';

  return <Navigate to={to} replace={true} />;
};
