import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const factVerificationWorkflowConfigSchema = z.object({
  name: z.string().min(3),
  description: z.string().optional(),
  factVerifierModelPromptTemplateId: z.string(),
  dataSetIds: z.array(z.string().uuid()).min(1),
  modelPromptTemplateIds: z.array(z.string().uuid()).min(1),
});

export type FactVerificationWorkflowConfig = z.infer<
  typeof factVerificationWorkflowConfigSchema
>;

const factGenerationInputSchema = z.object({
  sourceArtifactId: z.string().uuid(),
});

export type FactGenerationInput = z.infer<typeof factGenerationInputSchema>;

const evalHashIdInputSchema = z.object({
  id: z.string().uuid(),
});

export type EvalHashIdSchema = z.infer<typeof evalHashIdInputSchema>;

export const evalContract = c.router(
  {
    startCustomFactVerification: {
      method: 'POST',
      path: '/fact-verification',
      body: factVerificationWorkflowConfigSchema,
      responses: {
        201: z.object({
          workflowId: z.string().uuid(),
        }),
      },
    },
    getEvalHashedId: {
      method: 'GET',
      path: '/hash',
      query: evalHashIdInputSchema,
      responses: {
        200: z.object({
          hashedId: z.string().uuid(),
        }),
      },
    },
  },
  { pathPrefix: '/eval' },
);
