import { AppointmentSummary } from '@eluve/utils';

type Options = {
  addSignature?: boolean;
};

type AppointmentSummaryWithNotes = AppointmentSummary & {
  ADDITIONAL_NOTES?: string | null;
};

const appendSignature = (value?: string | null): string => {
  const { protocol } = window.location;
  let host = window.location.host;
  if (host.includes('localhost')) {
    // Jane does not allow IPs or localhost in URLs
    host = 'web.eluve.local:4200';
  }
  const url = `${protocol}//${host}${window.location.pathname}`;
  const signature = `*This note was drafted in part by Eluve, an AI Assistant for medical professionals. To see this chart in Eluve, visit: [${url}](${url})*`;
  if (!value) {
    return signature;
  }
  return `${value}<p><br/><p/>${signature}`;
};

export const combineSummaryWithNotes = (
  summary: Record<string, unknown>,
  notes?: string | null,
  options: Options = {},
): AppointmentSummaryWithNotes => {
  const { addSignature = true } = options;
  return {
    ...summary,
    ADDITIONAL_NOTES: addSignature ? appendSignature(notes) : notes,
  };
};
