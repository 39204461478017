import { useLazyQuery } from '@apollo/client';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { X } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';
import {
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  PlaceholderProps,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';

import { MedicalCode, MedicalCodeTag } from '@eluve/blocks';
import { HStack, Icon } from '@eluve/components';
import { ResultOf } from '@eluve/graphql.tada';

import { searchBillingCodesQuery } from './SearchableAppointmentBillingCodes.operations';

export type BillingCodeOption = Omit<
  ResultOf<typeof searchBillingCodesQuery>['searchMedicalCodes'][0],
  '__typename'
>;

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <X className="size-[10px]" />
    </components.MultiValueRemove>
  );
};

const Placeholder = (props: PlaceholderProps<BillingCodeOption>) => {
  return (
    <HStack className="text-brandGray600 col-start-1 col-end-3 row-start-1 row-end-2 inline-flex gap-3">
      <Icon size="xs" name="Search" />
      <components.Placeholder {...props} />
    </HStack>
  );
};

// We'll let the container take care of rendering the whole billing code
// so we don't need an actual label
const MultiValueLabel = (_props: MultiValueGenericProps<BillingCodeOption>) => {
  return null;
};

const MultiValueContainer = (
  props: MultiValueGenericProps<BillingCodeOption>,
) => {
  const { data } = props;
  const code = data as BillingCodeOption;

  return (
    <MedicalCode
      code={code.code}
      codeType={code.codeType}
      includeDescription={true}
      description={code.description ?? ''}
      endAdornment={<components.MultiValueRemove {...props} />}
    />
  );
};

const Option = (props: OptionProps<BillingCodeOption>) => {
  const { data } = props;
  const code = data as BillingCodeOption;

  return (
    <components.Option {...props} className="flex items-center gap-4">
      <MedicalCodeTag code={code.code} codeType={code.codeType} />
      <div>{code.description}</div>
    </components.Option>
  );
};

export interface SearchableAppointmentBillingCodesProps {
  selectedCodes?: BillingCodeOption[];
  onCodeAdded?: (option: BillingCodeOption) => void | Promise<void>;
  onCodeRemoved?: (option: BillingCodeOption) => void | Promise<void>;
}

// This ensures that Emotion's styles are inserted before Tailwind's styles so that Tailwind classes have precedence over Emotion
// https://react-select.com/styles#the-classnames-prop
const EmotionCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const cache = React.useMemo(
    () =>
      createCache({
        key: 'with-tailwind',
        insertionPoint: document.querySelector('title')!,
      }),
    [],
  );

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export const SearchableAppointmentBillingCodes: React.FC<
  SearchableAppointmentBillingCodesProps
> = ({ selectedCodes, onCodeAdded, onCodeRemoved }) => {
  const [search] = useLazyQuery(searchBillingCodesQuery);

  const _searchCodes = useCallback(
    (query: string, callback: (codes: BillingCodeOption[]) => void) => {
      search({ variables: { query, limit: 25 } }).then((result) => {
        callback(result.data?.searchMedicalCodes ?? []);
      });
    },
    [search],
  );

  const searchCodes = useMemo(
    () => debounce(_searchCodes, 250),
    [_searchCodes],
  );

  return (
    <EmotionCacheProvider>
      <AsyncSelect<BillingCodeOption, true>
        placeholder="Choose codes you would like to add"
        noOptionsMessage={({ inputValue }) =>
          inputValue
            ? `No results for "${inputValue}"`
            : 'Enter code or search term'
        }
        loadingMessage={() => 'Searching...'}
        isMulti
        value={selectedCodes}
        isClearable={false}
        classNames={{
          container: () => 'w-full',
          control: () => 'p-2 border rounded-[10px] border-brandGray200',
          valueContainer: () => 'gap-2',
          menu: () => 'shadow-all-around rounded-[10px] p-3 mt-3 z-[9999]',
          placeholder: () => 'p-2 text-brandGray600',
          multiValue: () => 'bg-transparent',
          indicatorSeparator: () => 'hidden',
          dropdownIndicator: () => 'hidden',
          loadingIndicator: () => 'hidden',
          indicatorsContainer: () => 'hidden',
          multiValueRemove: () =>
            'rounded-full p-0.5 bg-brandGray800 text-brandGray50',
          option: ({ isFocused }) =>
            clsx('p-2 my-2 rounded-sm', {
              'bg-brandGray200': isFocused,
            }),
        }}
        onChange={async (input) => {
          const codes = selectedCodes ?? [];
          const removed = codes.filter((f) => !input.includes(f));
          const added = input.filter((f) => !codes.includes(f));

          if (removed.length) {
            const [toRemove] = removed;
            await onCodeRemoved?.(toRemove!);
          }

          if (added.length) {
            const [toAdd] = added;
            await onCodeAdded?.(toAdd!);
          }
        }}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.code}
        components={{
          MultiValueContainer,
          MultiValueLabel,
          MultiValueRemove,
          Option,
          Placeholder,
        }}
        loadOptions={searchCodes}
      />
    </EmotionCacheProvider>
  );
};
